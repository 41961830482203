import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "redux/store";
import { api } from "api/axiosInterceptor";
import {
  decrementRequests,
  incrementRequests,
  setIsLoading,
  setShowToast,
} from "./application";
import {
  BusinessProblem,
  CoreBusiness,
  GenerateCriteria,
  IBuildState,
  ICriteria,
  ISection,
  Objectives,
  QuestionAnswer,
  RefinementQuestion,
  RequirementData,
} from "types/build";
import { apiUrl } from "api/apiUrl";

const initialState: IBuildState = {
  coreBusiness: {
    Is_problem_statement_sufficient: false,
    product_category: "",
  },
  businessObjectives: {
    objectives: [],
  },
  generateSection: {
    sections: [],
  },
  generateCriteria: [],
  requirementsResponse: {
    is_refined: false,
    requirements: {},
  },
  refinementQuestion: [],
  businessProblemQuestion: {
    queries: [],
    solution: "",
  },
};

const slice = createSlice({
  name: "build",
  initialState,
  reducers: {
    businessProblemQueries(state, action: PayloadAction<BusinessProblem>) {
      state.businessProblemQuestion = action.payload;
    },
    coreBusinessCheck(state, action: PayloadAction<CoreBusiness>) {
      state.coreBusiness = action.payload;
    },
    coreBusinessGetObjectives(state, action: PayloadAction<Objectives>) {
      state.businessObjectives = action.payload;
    },
    coreBusinessSection(state, action: PayloadAction<ISection>) {
      state.generateSection = action.payload;
    },
    coreBusinessCriteria(state, action: PayloadAction<GenerateCriteria>) {
      state.generateCriteria = action.payload;
    },
    generateRequirement(state, action: PayloadAction<RequirementData>) {
      state.requirementsResponse = action.payload;
    },
    generateRefinementQuestion(
      state,
      action: PayloadAction<RefinementQuestion[]>
    ) {
      state.refinementQuestion = action.payload;
    },
  },
});

export const {
  businessProblemQueries,
  coreBusinessCheck,
  coreBusinessGetObjectives,
  coreBusinessSection,
  coreBusinessCriteria,
  generateRequirement,
  generateRefinementQuestion,
} = slice.actions;

export default slice.reducer;

export function getBusinessProblemQueries() {
  return async (dispatch: AppDispatch) => {
    dispatch(incrementRequests());
    try {
      const path = "/business-problem-queries/";
      const response = await api.get(path);
      dispatch(businessProblemQueries(response.data));
      return response.status;
    } catch (error) {
      dispatch(
        setShowToast({
          show: true,
          type: "error",
          toastMessage: "Something went wrong!",
        })
      );
    } finally {
      dispatch(decrementRequests());
    }
  };
}

export function setBusinessProblemQueries(businessProblem: any) {
  return async (dispatch: AppDispatch) => {
    dispatch(setIsLoading(true));
    try {
      const path = "/business-problem-queries/";
      const { data, status } = await api.post(path, businessProblem);
      dispatch(coreBusinessGetObjectives(data));
      return { data, status };
    } catch (error) {
      dispatch(
        setShowToast({
          show: true,
          type: "error",
          toastMessage: "Something went wrong!",
        })
      );
    } finally {
      dispatch(setIsLoading(false));
    }
  };
}

export function coreBusinsessProblemCheck(solution: string) {
  return async (dispatch: AppDispatch) => {
    dispatch(incrementRequests());
    try {
      const path = apiUrl?.coreBusinessCheck;
      const obj = {
        solution,
      };
      const { data, status } = await api.post(path, obj);
      dispatch(coreBusinessCheck(data));
      return { data, status };
    } catch (error) {
      dispatch(
        setShowToast({
          show: true,
          type: "error",
          toastMessage: "Something went wrong!",
        })
      );
    } finally {
      dispatch(decrementRequests());
    }
  };
}

export function getCoreBusinessSection() {
  return async (dispatch: AppDispatch) => {
    dispatch(setIsLoading(true));
    try {
      const path = apiUrl?.generateSections;
      const response = await api.get(path);
      dispatch(coreBusinessSection(response.data));
      return response.status;
    } catch (error) {
      dispatch(
        setShowToast({
          show: true,
          type: "error",
          toastMessage: "Something went wrong!",
        })
      );
    } finally {
      dispatch(setIsLoading(false));
    }
  };
}

export function getCoreBusinessCriteria() {
  return async (dispatch: AppDispatch) => {
    try {
      const path = apiUrl?.generateCriteria;
      const response = await api.get(path);
      dispatch(coreBusinessCriteria(response.data));
      return response.status;
    } catch (error) {
      dispatch(
        setShowToast({
          show: true,
          type: "error",
          toastMessage: "Something went wrong!",
        })
      );
    }
  };
}

export function generateSections(sections: string[]) {
  return async (dispatch: AppDispatch) => {
    try {
      const path = apiUrl?.generateSections;
      const data = {
        sections,
      };
      const response = await api.post(path, data);
      return response.status;
    } catch (error) {
      dispatch(
        setShowToast({
          show: true,
          type: "error",
          toastMessage: "Something went wrong!",
        })
      );
    }
  };
}

export function generateCriteria(criteria: ICriteria[]) {
  return async (dispatch: AppDispatch) => {
    dispatch(incrementRequests());
    try {
      const path = apiUrl?.generateCriteria;
      const response = await api.post(path, criteria);
      return response.status;
    } catch (error) {
      dispatch(
        setShowToast({
          show: true,
          type: "error",
          toastMessage: "Something went wrong!",
        })
      );
    } finally {
      dispatch(decrementRequests());
    }
  };
}

export function getRequirements() {
  return async (dispatch: AppDispatch) => {
    dispatch(incrementRequests());
    try {
      const path = apiUrl.generateRequirements;
      const response = await api.get(path);
      dispatch(generateRequirement(response.data));
      return response.status;
    } catch (error: any) {
      const status = error.response ? error.response.status : 500;
      if (status !== 500 && status !== 502) {
        dispatch(
          setShowToast({
            show: true,
            type: "error",
            toastMessage: "Something went wrong!",
          })
        );
      }
      return status;
    } finally {
      dispatch(decrementRequests());
    }
  };
}

export function downloadRequirements() {
  return async (dispatch: AppDispatch) => {
    dispatch(incrementRequests());
    try {
      const path = "/download/requirement/";
      const response = await api.get(path, {
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;

      const contentDisposition = response.headers["content-disposition"];
      const fileName =
        contentDisposition?.match(/filename="(.+)"/)?.[1] || "downloaded_file";

      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();

      dispatch(
        setShowToast({
          show: true,
          type: "success",
          toastMessage: "File downloaded successfully!",
        })
      );
      return response.status;
    } catch (error) {
      dispatch(
        setShowToast({
          show: true,
          type: "error",
          toastMessage: "Failed to download file. Please try again!",
        })
      );
    } finally {
      dispatch(decrementRequests());
    }
  };
}

export function getRefinementQuestion() {
  return async (dispatch: AppDispatch) => {
    dispatch(incrementRequests());
    try {
      const path = "/refinement-questions/";
      const response = await api.get(path);
      dispatch(generateRefinementQuestion(response.data));
      return response.status;
    } catch (error: any) {
      const status = error.response ? error.response.status : 500;
      if (status !== 500 && status !== 502 && status !== 400) {
        dispatch(
          setShowToast({
            show: true,
            type: "error",
            toastMessage: "Something went wrong!",
          })
        );
      }
      return status;
    } finally {
      dispatch(decrementRequests());
    }
  };
}

export function setRefinementQuestion(data: QuestionAnswer[]) {
  return async (dispatch: AppDispatch) => {
    dispatch(incrementRequests());
    try {
      const path = "/refine-questions/";
      const response = await api.post(path, data);
      return response.status;
    } catch (error: any) {
      const status = error.response ? error.response.status : 500;
      if (status !== 500 && status !== 502 && status !== 400) {
        dispatch(
          setShowToast({
            show: true,
            type: "error",
            toastMessage: "Something went wrong!",
          })
        );
      }
      return status;
    } finally {
      dispatch(decrementRequests());
    }
  };
}
