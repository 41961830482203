import React from "react";
import Typography from "./Typography";
import {
  CheckCircleFilled,
  LoadingOutlined,
  SmallDashOutlined,
} from "@ant-design/icons";
import { Spin } from "antd";

interface Step {
  question: string;
}

interface ProgressBarComponentProps {
  steps: (string | Step)[];
  currentStatus: number;
  progress: number;
  progressHeading?: string;
  className?: string;
  message?: string;
}

const ProgressBarComponent: React.FC<ProgressBarComponentProps> = ({
  steps,
  currentStatus,
  progress,
  message,
  progressHeading = "In Progress",
  className,
}) => {
  console.log("🚀 ~ message:", message);
  const stepHeight = 34;
  const visibleSteps = 5;

  const maxScrollableIndex = Math.max(0, steps?.length - visibleSteps);

  const adjustedCurrentStatus = Math.min(
    Math.max(0, currentStatus - 2),
    maxScrollableIndex
  );

  return (
    <div
      className={`gap-8 sm:w-[300px] md:w-[350px] lg:w-[400px] ${className}`}
    >
      {/* Progress Heading */}
      <Typography variant="Heading 4" text={progressHeading} />

      {/* Progress Bar */}
      <div className="mb-4 flex flex-row items-center gap-4">
        <div className="relative w-full h-2 bg-[#0000000F] rounded">
          <div
            className="h-2 bg-blue-primary rounded"
            style={{
              width: `${progress}%`,
              transition: "width 0.5s ease-in-out",
            }}
          />
        </div>
        <Typography variant="Base" text={`${Math.round(progress)}%`} />
      </div>

      <Typography variant="Base" text={`${message}`} />

      {/* Steps List */}
      <ul className="list-none space-y-3 relative overflow-hidden h-[11rem]">
        {steps?.map((step, index) => {
          const stepIndex = steps.indexOf(step);
          const stepText = typeof step === "string" ? step : step.question;

          return (
            <li
              key={index}
              className="flex items-center gap-3 text-lg"
              style={{
                transform: `translateY(-${adjustedCurrentStatus * stepHeight}px)`,
                transition: "transform 0.9s ease-in-out",
              }}
            >
              {/* Icons based on progress */}
              {progress === 100 || stepIndex < currentStatus ? (
                <CheckCircleFilled className="text-[#52C41A] h-4 w-4" />
              ) : stepIndex === currentStatus ? (
                <Spin
                  indicator={<LoadingOutlined spin />}
                  size="small"
                  className="text-blue-primary"
                />
              ) : (
                <SmallDashOutlined className="text-blue-primary w-4" />
              )}

              {/* Step Text */}
              <Typography
                variant="Base"
                text={
                  stepText.length > 40
                    ? `${stepText.slice(0, 40)}...`
                    : stepText
                }
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ProgressBarComponent;
