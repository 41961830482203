import React from "react";
import Typography from "./Typography";

interface LoadingProps {
  mainHeading?: string;
  headingText?: string;
  subText?: string;
}

const Loading: React.FC<LoadingProps> = ({
  mainHeading = "Importing your data",
  headingText = "This may take a few minutes",
  subText = "This window will close automatically.",
}) => {
  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-[9999]">
      <div className="flex flex-col justify-between items-center p-4 sm:p-8 gap-8 w-[80%] sm:w-[26%] rounded-lg shadow-md border bg-white">
        <Typography variant="Heading 3" text={mainHeading} />
        {/* Default spinner loader */}
        <div
          className="w-10 h-10 border-4 border-t-blue-primary border-solid rounded-full animate-spin"
          role="status"
        />
        <div className="flex flex-col w-full bg-[#F0F5FF] items-center py-4 gap-2">
          <Typography variant="Heading 5" text={headingText} strong />
          <Typography variant="SM" text={subText} />
        </div>
      </div>
    </div>
  );
};

export default Loading;
