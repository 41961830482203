import React, { useEffect, useState } from "react";
import Typography from "style-guide/component/Typography";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { RootState } from "redux/store";
import { useSelector } from "react-redux";
import { ChipsData } from "types/templates";

// Define the structure of the requirements object
type CriteriaRequirements = {
  [criteriaKey: string]: {
    requirements: string[];
  };
};

type RequirementsData = {
  [reqKey: string]: CriteriaRequirements;
};

type CurrentComponentType = "add_requirement" | "";

interface RequirementProps {
  currentComponent: CurrentComponentType;
  chipsData: ChipsData;
  setChipsData: React.Dispatch<React.SetStateAction<ChipsData>>;
}

const Requirements: React.FC<RequirementProps> = ({
  currentComponent,
  chipsData,
  setChipsData,
}) => {
  const [showInput, setShowInput] = useState<Record<string, boolean>>({});
  const [inputValue, setInputValue] = useState<string>("");
  const [requirements, setRequirements] = useState<RequirementsData>({});
  const { requirementsResponse } = useSelector(
    (state: RootState) => state.buildData
  );

  useEffect(() => {
    // Initialize requirements from the response
    setRequirements(requirementsResponse?.requirements);
  }, [requirementsResponse]);

  useEffect(() => {
    if (requirements) {
      const initialChipsData: ChipsData = {};

      Object.keys(requirements).forEach((reqKey) => {
        initialChipsData[reqKey] = {};
        Object.keys(requirements[reqKey]).forEach((criteriaKey) => {
          initialChipsData[reqKey][criteriaKey] = {
            requirements: requirements[reqKey][criteriaKey].requirements || [],
          };
        });
      });

      setChipsData(initialChipsData);
    }
  }, [requirements, setChipsData]);

  const RequirementKeys = Object.keys(requirements || {});

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    reqKey: string,
    criteriaKey: string
  ) => {
    if (e.key === "Enter" && e.currentTarget.value.trim() !== "") {
      const newChip = e.currentTarget.value.trim();
      setChipsData((prev) => {
        const updatedRequirements = [
          ...prev[reqKey][criteriaKey].requirements,
          newChip,
        ];

        const updatedChipsData = {
          ...prev,
          [reqKey]: {
            ...prev[reqKey],
            [criteriaKey]: { requirements: updatedRequirements },
          },
        };
        return updatedChipsData;
      });

      setShowInput((prev) => ({
        ...prev,
        [`${reqKey}-${criteriaKey}`]: false,
      }));
      e.currentTarget.value = "";
    }
  };

  const handleDeleteChip = (
    reqKey: string,
    criteriaKey: string,
    chipIndex: number
  ) => {
    setChipsData((prev) => {
      const updatedRequirements = prev[reqKey][criteriaKey].requirements.filter(
        (_, i) => i !== chipIndex
      );

      return {
        ...prev,
        [reqKey]: {
          ...prev[reqKey],
          [criteriaKey]: { requirements: updatedRequirements },
        },
      };
    });
  };

  const handleAddClick = (reqKey: string, criteriaKey: string) => {
    setInputValue("");
    setShowInput((prev) => ({
      ...prev,
      [`${reqKey}-${criteriaKey}`]: true,
    }));
  };

  return (
    <>
      <div className="flex flex-col gap-8">
        {/* Instruction Section */}
        <div className="gap-2 flex flex-col justify-between">
          <Typography
            text={
              currentComponent === "add_requirement"
                ? "Here are your suggested requirements."
                : ""
            }
            variant="Heading 3"
            className="text-black"
          />
          <Typography
            text={
              currentComponent === "add_requirement"
                ? "We've generated these requirements based on your inputs and what similar firms have used. You can edit them or add your own to make sure they fit your specific needs."
                : ""
            }
            variant="LG"
            className="text-black"
          />
        </div>

        {/* Mapping requirements and criteria */}
        <div className="w-full flex pt-4 flex-col gap-4 border-[#F0F0F0] border-[1px] rounded-md">
          {RequirementKeys.map((reqKey) => (
            <div className="w-full" key={reqKey}>
              <Typography
                text={reqKey}
                variant="Heading 4"
                className="mb-2 pl-4"
              />
              <table className="w-full border border-[#E0E0E0] rounded-md">
                <thead>
                  <tr>
                    <th className="p-4 text-left">
                      <Typography variant="Base" text="Criteria" strong />
                    </th>
                    <th className="p-4 text-left">
                      <Typography variant="Base" text="Requirements" strong />
                    </th>
                    <th className="p-4 text-left">
                      <div>
                        <Typography variant="Base" text="Weight" strong />
                      </div>
                    </th>
                    <th className="p-4 text-left">
                      <Typography variant="Base" text="Scorers" strong />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(requirements[reqKey] || {}).map(
                    (criteriaKey) => {
                      return (
                        <tr key={criteriaKey} className="h-[50px]">
                          <td className="border border-[#0000000F] px-4 w-[340px] h-full">
                            <Typography
                              variant="Base"
                              text={criteriaKey}
                              strong
                            />
                          </td>
                          <td className="border border-[#0000000F] w-[640px] h-full">
                            <div className="w-full p-2 rounded box-sizing">
                              <div className="flex flex-wrap gap-2 mb-2">
                                {currentComponent === "add_requirement" && (
                                  <>
                                    {showInput[`${reqKey}-${criteriaKey}`] ? (
                                      <input
                                        className="w-full border-none outline-none p-1 rounded text-[12px]"
                                        placeholder="Add requirement"
                                        value={inputValue}
                                        onChange={(e) =>
                                          setInputValue(e.target.value)
                                        }
                                        onKeyDown={(e) =>
                                          handleKeyDown(e, reqKey, criteriaKey)
                                        }
                                        onBlur={() =>
                                          setShowInput((prev) => ({
                                            ...prev,
                                            [`${reqKey}-${criteriaKey}`]: false,
                                          }))
                                        }
                                        autoFocus
                                      />
                                    ) : (
                                      <div
                                        className="flex flex-row gap-2 items-center bg-[#0000000F] rounded-md px-2 py-1 cursor-pointer"
                                        onClick={() =>
                                          handleAddClick(reqKey, criteriaKey)
                                        }
                                      >
                                        <Typography
                                          variant="SM"
                                          text="Add requirement"
                                          strong
                                        />
                                        <PlusOutlined className="h-3 w-3" />
                                      </div>
                                    )}
                                  </>
                                )}
                                {/* Existing chips */}
                                {(
                                  chipsData[reqKey]?.[criteriaKey]
                                    ?.requirements || []
                                ).map((chip: string, chipIndex: number) => (
                                  <div
                                    key={chipIndex}
                                    className="flex items-center bg-[#0000000F] rounded-md px-2 py-1"
                                  >
                                    <Typography variant="SM" text={chip} />
                                    {currentComponent === "add_requirement" && (
                                      <CloseOutlined
                                        className="ml-1 cursor-pointer h-3 w-3"
                                        onClick={() =>
                                          handleDeleteChip(
                                            reqKey,
                                            criteriaKey,
                                            chipIndex
                                          )
                                        }
                                      />
                                    )}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </td>
                          <td className="w-[150px] border p-2 text-center h-full">
                            <input className="w-full h-full border-none outline-none p-1 rounded" />
                          </td>
                          <td className="w-[150px] border p-2 text-center h-full">
                            <input className="w-full h-full border-none outline-none p-1 rounded" />
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </table>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Requirements;
