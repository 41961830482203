import React, { useEffect, useState, useRef } from "react";
import { SetURLSearchParams, useNavigate } from "react-router-dom";
import { Badge } from "antd";
import Button from "style-guide/component/Button";
import Typography from "style-guide/component/Typography";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { AppDispatch } from "redux/store";
import { setRefinementQuestion } from "../../../../redux/slice/build";
import { setShowToast } from "../../../../redux/slice/application";
import { useWebSocket } from "api/websocket";
import Tooltip from "style-guide/component/Tooltip";

type Parameter = {
  name: string;
  value: string;
  description: string;
};

type Question = {
  question: string;
  sample_answer: {
    text: string;
    parameters: Parameter[];
  };
};

interface QuestionProps {
  refine: any;
  searchParams: any;
  currentQuestion: number;
  setSearchParams: SetURLSearchParams;
  setCurrentQuestion: React.Dispatch<React.SetStateAction<number>>;
}

const NewQuestion: React.FC<QuestionProps> = ({
  refine,
  currentQuestion,
  setCurrentQuestion,
  setSearchParams,
  searchParams,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const divRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { clearMessage } = useWebSocket();
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(true);
  const [finalAnswers, setFinalAnswers] = useState<Record<number, string>>(
    () => {
      const savedAnswers = localStorage.getItem("answers");
      return savedAnswers ? JSON.parse(savedAnswers) : {};
    }
  );
  const [edit, setEdit] = useState<boolean>(true);
  const [textValue, setTextValue] = useState<string>("");

  const formattedQuestions = refine.map((item: any) => ({
    question: item.question,
    sample_answer: {
      text: item?.sample_answer?.text,
      parameters: item?.sample_answer?.parameters.map((param: any) => ({
        name: param.name,
        value: "",
        description: param.description,
      })),
    },
  }));

  const [questions, setQuestions] = useState<Question[]>(formattedQuestions);

  const getTextWithParameters = (text: string, parameters: Parameter[]) => {
    let updatedText = text;
    parameters.forEach((param) => {
      const placeholder = `{${param.name}}`;
      if (param.value) {
        updatedText = updatedText.replace(placeholder, `{${param.value}}`);
      }
    });
    return updatedText;
  };

  const updateParameterValue = (parameterName: string, newValue: string) => {
    setQuestions((prevQuestions) => {
      const newQuestions = [...prevQuestions];
      const parameters =
        newQuestions[currentQuestion]?.sample_answer?.parameters || [];
      const paramIndex = parameters.findIndex((p) => p.name === parameterName);
      if (paramIndex !== -1) {
        parameters[paramIndex] = { ...parameters[paramIndex], value: newValue };
      }

      const updatedText = getTextWithParameters(
        newQuestions[currentQuestion]?.sample_answer?.text || "",
        parameters
      );

      setTextValue(updatedText);
      setFinalAnswers((prev) => ({
        ...prev,
        [currentQuestion]: updatedText,
      }));

      return newQuestions;
    });
  };

  const handleTextChange = (newText: string) => {
    setTextValue(newText);

    setQuestions((prevQuestions) => {
      const newQuestions = [...prevQuestions];
      const currentQ = newQuestions[currentQuestion];

      const regex = /\{([^}]+)\}/g;
      const matches = Array.from(newText.matchAll(regex));
      const newParams = matches.map((match) => {
        const paramName = match[1];
        const existingParam = currentQ?.sample_answer?.parameters.find(
          (p) => p.name === paramName
        );
        return existingParam || { name: paramName, value: "", description: "" };
      });

      currentQ.sample_answer.text = newText;
      currentQ.sample_answer.parameters = newParams;

      return newQuestions;
    });

    setFinalAnswers((prev) => ({
      ...prev,
      [currentQuestion]: newText,
    }));
  };

  const renderText = (text: string, parameters: Parameter[]) => {
    const parts = [];
    let lastIndex = 0;
    parameters?.forEach((param, index) => {
      const placeholder = `{${param.name}}`;
      const placeholderIndex = text.indexOf(placeholder, lastIndex);
      if (placeholderIndex !== -1) {
        parts.push(text?.substring(lastIndex, placeholderIndex));
        const displayValue = param.value || param.name;
        parts.push(
          <span key={index} className={displayValue ? "text-[green]" : ""}>
            {`{${displayValue}}`}
          </span>
        );
        lastIndex = placeholderIndex + placeholder.length;
      }
    });
    parts.push(text?.substring(lastIndex));
    return <>{parts}</>;
  };

  useEffect(() => {
    const savedCurrentQuestion = localStorage.getItem("currentQuestion");
    if (savedCurrentQuestion) {
      setCurrentQuestion(Number(savedCurrentQuestion));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("currentQuestion", String(currentQuestion));
  }, [currentQuestion]);

  const handleNext = () => {
    setFinalAnswers((prev) => {
      const updatedAnswers = { ...prev, [currentQuestion]: textValue };
      localStorage.setItem("answers", JSON.stringify(updatedAnswers));
      return updatedAnswers;
    });

    if (currentQuestion < questions.length - 1) {
      const nextQuestion = currentQuestion + 1;
      setCurrentQuestion(nextQuestion);
      setSearchParams({ currentQuestion: nextQuestion.toString() });
    }

    setEdit(true);
  };

  const extractParameters = (
    answer: string,
    question: Question
  ): Parameter[] => {
    const regex = /\{([^}]+)\}/g;
    const matches = Array.from(answer.matchAll(regex));
    return matches.map((match) => {
      const paramName = match[1];
      const existingParam = question?.sample_answer?.parameters.find(
        (p) => p.name === paramName
      );
      return existingParam || { name: paramName, value: "", description: "" };
    });
  };

  const loadAnswer = (questionIndex: number) => {
    const savedAnswers = JSON.parse(localStorage.getItem("answers") || "{}");
    const savedAnswer = savedAnswers[questionIndex];

    if (savedAnswer) {
      setTextValue(savedAnswer);

      const updatedQuestions = [...questions];
      const parameters = extractParameters(
        savedAnswer,
        updatedQuestions[questionIndex]
      );

      updatedQuestions[questionIndex] = updatedQuestions[questionIndex] || {};
      updatedQuestions[questionIndex].sample_answer =
        updatedQuestions[questionIndex].sample_answer || {};
      updatedQuestions[questionIndex].sample_answer.parameters = parameters;
      setQuestions(updatedQuestions);
      setIsPopupOpen(false);
    } else {
      setTextValue("");
      setIsPopupOpen(true);
    }
  };

  useEffect(() => {
    loadAnswer(currentQuestion);
  }, [currentQuestion]);

  const handleBack = () => {
    setFinalAnswers((prev) => {
      const updatedAnswers = { ...prev, [currentQuestion]: textValue };
      localStorage.setItem("answers", JSON.stringify(updatedAnswers));
      return updatedAnswers;
    });

    if (currentQuestion > 0) {
      const previousQuestion = currentQuestion - 1;
      setCurrentQuestion(previousQuestion);
      loadAnswer(previousQuestion); // Load the previous question's answer
    } else {
      navigate(
        "/build_scratch/requirements_criteira/?component=add_requirement"
      );
    }
  };

  useEffect(() => {
    if (textValue !== finalAnswers[currentQuestion]) {
      setEdit(true);
    }
  }, [textValue, currentQuestion, finalAnswers]);

  const handleSave = async () => {
    clearMessage();
    const currentIndex = Number(searchParams.get("currentQuestion")) || 0;
    const storedAnswers = JSON.parse(localStorage.getItem("answers") || "{}");
    storedAnswers[currentIndex] = finalAnswers[currentIndex] || "";
    localStorage.setItem("answers", JSON.stringify(storedAnswers));
    if (currentIndex < refine.length - 1) {
      const nextQuestion = currentIndex + 1;
      setCurrentQuestion(nextQuestion);
      setSearchParams({ question: nextQuestion.toString() });
      localStorage.setItem("currentQuestion", nextQuestion.toString());
      setIsPopupOpen(true);
    } else {
      const payload = refine.map((q: any, index: number) => ({
        question: q.question || "",
        answer: storedAnswers[index] || "",
      }));

      const maxAttempts = 5;
      let attempts = 0;
      const tryApiCall = async (): Promise<void> => {
        attempts++;
        try {
          const response = await dispatch(setRefinementQuestion(payload));

          if (response === 200) {
            localStorage.removeItem("answers");
            localStorage.removeItem("currentQuestion");
            navigate(
              "/build_scratch/requirements_criteira/?component=add_requirement"
            );
            return;
          }

          if (response === 500 && attempts < maxAttempts) {
            await tryApiCall();
          } else if (attempts >= maxAttempts) {
            dispatch(
              setShowToast({
                show: true,
                type: "error",
                toastMessage:
                  "Maximum retry attempts reached. Please try again later!",
              })
            );
          }
        } catch (error) {
          if (attempts < maxAttempts) {
            await tryApiCall();
          } else {
            dispatch(
              setShowToast({
                show: true,
                type: "error",
                toastMessage:
                  "An unexpected error occurred. Please try again later.",
              })
            );
          }
        }
      };

      tryApiCall();
    }
  };

  const handleUseExample = () => {
    const currentText = questions[currentQuestion]?.sample_answer.text || "";
    setTextValue(currentText);
    setFinalAnswers((prev) => ({
      ...prev,
      [currentQuestion]: currentText,
    }));
    setIsPopupOpen(false);
    setEdit(false);
  };

  return (
    <div className="flex flex-col gap-4 w-full max-w-[800px] px-4 relative">
      <Typography
        variant="Heading 4"
        text={questions[currentQuestion]?.question}
      />

      <div
        className="relative w-full flex flex-col gap-8"
        ref={divRef}
        onClick={() => setEdit(true)}
      >
        {edit ? (
          <div className="relative w-full border rounded-md p-2 bg-white overflow-hidden">
            <textarea
              className="w-full min-h-[200px] border-none outline-none bg-transparent resize-none caret-black text-black whitespace-pre-wrap font-sans text-sm font-normal leading-[22px]"
              placeholder={questions[currentQuestion]?.sample_answer.text}
              value={textValue}
              onChange={(e) => handleTextChange(e.target.value)}
              onInput={(e) => {
                const target = e.target as HTMLTextAreaElement;
                target.style.height = "auto";
                target.style.height = `${target.scrollHeight}px`;
              }}
              style={{
                width: "100%",
                height: "auto",
                overflow: "hidden",
              }}
            />
          </div>
        ) : (
          <>
            {!isPopupOpen && (
              <div className="mb-4 p-4 bg-white rounded-lg shadow font-sans text-sm font-normal leading-[22px] text-black">
                {renderText(
                  questions[currentQuestion]?.sample_answer.text,
                  questions[currentQuestion]?.sample_answer.parameters
                )}
              </div>
            )}
          </>
        )}
      </div>

      {isPopupOpen && (
        <div className="absolute top-20 left-full ml-4 z-30">
          <div className="flex flex-col gap-2 p-4 rounded-lg shadow-md border bg-white w-[320px]">
            <div className="flex gap-2">
              <InfoCircleOutlined className="text-[#52C41A]" />
              <Typography variant="Base" text="Add suggested example" strong />
            </div>
            <Typography
              variant="Base"
              text="We make it easy – add a suggested example that you can edit."
            />
            <div className="flex gap-2 justify-end">
              <Button
                type="Default"
                buttonText="No"
                onClick={() => setIsPopupOpen(false)}
                className="w-fit"
              />
              <Button
                type="Primary"
                buttonText="Yes"
                onClick={handleUseExample}
                className="w-fit"
              />
            </div>
          </div>
        </div>
      )}

      {!isPopupOpen && !localStorage.getItem(`answer-${currentQuestion}`) && (
        <Badge.Ribbon text="Nozma Tip" color="green">
          <div className="w-full border-[1px] border-[#52C41A] px-4 py-3 rounded-lg bg-[#F6FFED] mt-4 flex flex-col gap-2">
            <Typography
              variant="Base"
              text="Just a few steps before you can continue"
              strong
            />
            <Typography
              text="Please replace placeholders with actual values:"
              variant="SM"
            />
            <div className="flex flex-col gap-2">
              {questions[currentQuestion]?.sample_answer.parameters.map(
                (param) => (
                  <div key={param.name} className="flex flex-col">
                    <div className="flex flex-row gap-2 items-center">
                      <div className="bg-[#0000000F] w-fit px-2 rounded-md border-[1px] border-[#D9D9D9]">
                        <input
                          type="text"
                          placeholder={param.name}
                          value={param.value}
                          onChange={(e) =>
                            updateParameterValue(param.name, e.target.value)
                          }
                          className="text-[#000000E5] bg-transparent border-none outline-none placeholder:text-black text-[12px] font-normal leading-[20px]"
                        />
                      </div>
                      <Tooltip text={param.description} position="top">
                        <InfoCircleOutlined className="text-[#666666] cursor-pointer" />
                      </Tooltip>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </Badge.Ribbon>
      )}

      <div
        className={`h-[72px] fixed bottom-0 left-0 right-0 flex flex-row items-center bg-white p-4 border-t-[#D9D9D9] border-t-[1px] justify-between`}
      >
        <Button
          type="Default"
          size="Large"
          buttonText="Back"
          onClick={handleBack}
        />
        <Button
          type="Primary"
          size="Large"
          buttonText={
            currentQuestion === questions.length - 1 ? "Finish" : "Next"
          }
          onClick={
            currentQuestion === questions.length - 1 ? handleSave : handleNext
          }
        />
      </div>
    </div>
  );
};

export default NewQuestion;
