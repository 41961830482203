import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "redux/store";
import Menu from "style-guide/component/Menu";
import ProgressBar from "style-guide/component/Progressbar";
import Typography from "style-guide/component/Typography";
import Button from "style-guide/component/Button";
import CoreBusiness from "./CoreBusiness";
import ParsedDocument from "./ParsedDocument";
import { setBusinessProblemQueries } from "../../../../redux/slice/build";
import { setIntakeObjectives } from "../../../../redux/slice/overview";
import { Query } from "types/build";
import Loading from "style-guide/component/Loader";

const BuildTemplateScratch = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [solution, setSolution] = useState<string>("");
  const [objectives, setObjectives] = useState<string[]>([]);
  const [error, setError] = useState<string>("");
  const [isSectionLoading, setIsSectionLoading] = useState<boolean>(false);

  const [currentComponent, setCurrentComponent] = useState<
    "coreBusiness" | "parseDocument"
  >(
    new URLSearchParams(location.search).get("component") === "parseDocument"
      ? "parseDocument"
      : "coreBusiness"
  );

  const { businessProblemQuestion } = useSelector((state: RootState) => state.buildData);
  const { isLoading, isLoadingModal } = useSelector((state: RootState) => state.applicationData);

  const [answers, setAnswers] = useState<Query[]>(
    businessProblemQuestion?.queries?.map((q) => ({
      question: q.question,
      suggestion: q.suggestion,
      answer: "",
    })) || []
  );

  const handleNavigation = (component: "coreBusiness" | "parseDocument") => {
    navigate(`/build_scratch/overview/?component=${component}`);
    setCurrentComponent(component);
  };

  const handleBack = () => {
    currentComponent === "coreBusiness"
      ? navigate("/landing_page")
      : handleNavigation("coreBusiness");
  };

  const handleNext = async () => {
    if (currentComponent === "parseDocument") {
      setIsSectionLoading(true);
    }
    try {
      if (currentComponent === "coreBusiness") {
        const data = { queries: answers, solution };
        const response = await dispatch(setBusinessProblemQueries({ data }));
        if (
          response?.status === 200 &&
          response.data.Is_problem_statement_sufficient
        ) {
          handleNavigation("parseDocument");
          setError("");
        } else {
          setError("Core business solution must be valid");
        }
      } else {
        const data = { objectives, solution };
        const response = await dispatch(setIntakeObjectives(data));
        if (response === 200) {
          setCurrentStep(2);
          navigate("/build_scratch/template/?component=category");
        }
      }
    } catch (error) {
      setError("An error occurred while processing your request.");
    } finally {
      if (currentComponent === "parseDocument") {
        setIsSectionLoading(false);
      }
    }
  };

  useEffect(() => {
    if (businessProblemQuestion?.queries?.length > 0 && answers.length === 0) {
      setAnswers(
        businessProblemQuestion.queries.map((item) => ({
          question: item.question,
          suggestion: item.suggestion,
          answer: item.answer,
        }))
      );
    }
  }, [businessProblemQuestion]);

  return (
    <div className="overflow-x-auto w-full min-h-screen pb-[100px] pt-12 px-6 md:px-12 font-helvetica-neue flex flex-col gap-4">
      {/* Header */}
      <div className="flex flex-row justify-between">
        <Typography
          text="Nozma"
          variant="LG"
          className="text-blue-primary"
          strong
        />
        <Menu />
      </div>

      {/* Progress Bar */}
      <ProgressBar currentStep={currentStep} />

      {/* Loading Overlay */}
      {(isSectionLoading || isLoading || isLoadingModal) && (
        <div className="fixed inset-0 flex items-center justify-center z-[9999] text-center">
          <Loading
            mainHeading={
              isSectionLoading
                ? "Generating key sections to structure your evaluation."
                : isLoadingModal
                  ? "Analyzing your business needs."
                  : undefined
            }
            headingText={
              isSectionLoading
                ? "This will only take a few seconds."
                : isLoadingModal
                  ? "This may take a few moments."
                  : undefined
            }
          />
        </div>
      )}

      {/* Content */}
      <div className="flex-grow">
        {currentComponent === "coreBusiness" ? (
          <CoreBusiness
            question={businessProblemQuestion?.queries || []}
            answers={answers}
            setAnswers={setAnswers}
            setSolution={setSolution}
            solution={solution}
            error={error}
            setError={setError}
          />
        ) : (
          <ParsedDocument
            solution={solution}
            setSolution={setSolution}
            setObjectives={setObjectives}
            objectives={objectives}
          />
        )}
      </div>

      {/* Footer */}
      <div className="h-[72px] fixed bottom-0 left-0 right-0 flex flex-row justify-between items-center p-4 bg-white border-t-[#D9D9D9] border-t-[1px]">
        <Button
          type="Default"
          size="Large"
          buttonText="Back"
          onClick={handleBack}
        />
        <Button
          type={isLoading ? "Disabled" : "Primary"}
          size="Large"
          icon={false}
          buttonText="Next"
          onClick={handleNext}
        />
      </div>
    </div>
  );
};

export default BuildTemplateScratch;
