import CriticalRequirement from "./Requirement";
import { useEffect } from "react";
import Typography from "style-guide/component/Typography";
import Button from "style-guide/component/Button";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import { getRfpQuestions } from "../../../../redux/slice/rfp";
import Loading from "style-guide/component/Loader";
import ProgressBarComponent from "style-guide/component/Progress";
import { useWebSocket } from "api/websocket";

interface RequirementProps {
  openSelectedStep: (step: string) => void;
}

const Requirement: React.FC<RequirementProps> = ({ openSelectedStep }) => {
  const { message, clearMessage } = useWebSocket();
  const dispatch = useDispatch<AppDispatch>();

  const { isLoading } = useSelector(
    (state: RootState) => state.applicationData
  );
  useEffect(() => {
    clearMessage();
    dispatch(getRfpQuestions());
  }, []);

  const handleBack = () => {
    openSelectedStep("invite");
  };

  const handleNext = async () => {
    openSelectedStep("upload");
  };

  return (
    <>
      <div>
        <Typography
          text="Please review the questions below."
          variant="Heading 3"
        />
        <Typography
          text="Take a moment to review, select the critical requirements and upload category weights."
          variant="LG"
        />
      </div>

      {isLoading ? (
        <>
          {message?.info?.type === 1 ? (
            <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50">
              <ProgressBarComponent
                steps={message?.task_list}
                currentStatus={message?.status_id}
                progress={message?.info?.percentage || 0}
                message={message?.message || ''}
                progressHeading={message?.info?.progressbar}
              />
            </div>
          ) : (
            <div className="fixed inset-0 flex items-center justify-center z-[9999] text-center">
              <Loading mainHeading="Generating the Refinement Questions" />
            </div>
          )}
        </>
      ) : (
        <CriticalRequirement />
      )}

      <div className="h-[72px] fixed bottom-0 left-0 right-0 flex flex-row justify-between items-center bg-white p-4 border-t-[#D9D9D9] border-t-[1px]">
        <Button
          type="Default"
          size="Large"
          icon={false}
          buttonText="Back"
          onClick={handleBack}
        />
        <Button
          type="Primary"
          size="Large"
          icon={false}
          buttonText="Next"
          onClick={handleNext}
        />
      </div>
    </>
  );
};

export default Requirement;
