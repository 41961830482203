import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { api } from "api/axiosInterceptor";
import { AppDispatch } from "redux/store";
import {
  incrementRequests,
  decrementRequests,
  setShowToast,
  setIsLoading,
} from "./application";
import {
  ChipsData,
  CriteriaData,
  IRfpResponse,
  ITemplates,
  IWeight,
  WeightsData,
} from "../../types/templates";
import { apiUrl } from "api/apiUrl";

const initialState: ITemplates = {
  criteria: {},
  weights: {},
  rfpResponse: {
    is_rfp_response: false,
    company_name: "",
  },
};

const slice = createSlice({
  name: "templates",
  initialState,
  reducers: {
    criteriaAndReqirements(state, action: PayloadAction<CriteriaData>) {
      state.criteria = action.payload;
    },
    criteriaWeights(state, action: PayloadAction<IWeight>) {
      state.weights = action.payload;
    },
    repResponseCheck(state, action: PayloadAction<IRfpResponse>) {
      state.rfpResponse = action.payload;
    },
  },
});

export const { criteriaAndReqirements, criteriaWeights, repResponseCheck } =
  slice.actions;

export default slice.reducer;

export function getCriteriaWeights() {
  return async (dispatch: AppDispatch) => {
    dispatch(setIsLoading(true));
    try {
      const path = apiUrl.weight;
      const response = await api.get(path);
      dispatch(criteriaWeights(response.data));
      return response.status;
    } catch (error: any) {
      const status = error.response ? error.response.status : 500;
      dispatch(
        setShowToast({
          show: true,
          type: "error",
          toastMessage: "Something went wrong!",
        })
      );

      return status;
    } finally {
      dispatch(setIsLoading(false));
    }
  };
}

export function getCriteriaRquirements() {
  return async (dispatch: AppDispatch) => {
    dispatch(incrementRequests());
    try {
      const path = apiUrl.criteriaAndReqirements;
      const response = await api.get(path);
      dispatch(criteriaAndReqirements(response.data));
      return response.status;
    } catch (error: any) {
      const status = error.response ? error.response.status : 500;
      if (status !== 500 || status !== 502) {
        dispatch(
          setShowToast({
            show: true,
            type: "error",
            toastMessage: "Something went wrong!",
          })
        );
      }
      return status;
    } finally {
      dispatch(decrementRequests());
    }
  };
}

export function setCriteriaRequirements(data: ChipsData) {
  return async (dispatch: AppDispatch) => {
    dispatch(incrementRequests());
    try {
      const path = apiUrl?.criteriaAndReqirements;
      const response = await api.post(path, data);
      if (response.status === 200) {
        dispatch(getCriteriaRquirements());
      }
      return response.status;
    } catch (error) {
      dispatch(
        setShowToast({
          show: true,
          type: "error",
          toastMessage: "Something went wrong!",
        })
      );
    } finally {
      dispatch(decrementRequests());
    }
  };
}

export function setWeights(data: WeightsData) {
  return async (dispatch: AppDispatch) => {
    dispatch(incrementRequests());
    try {
      const path = apiUrl?.weight;
      const response = await api.post(path, data);
      if (response.status === 200) {
        dispatch(getCriteriaWeights);
      }
      return response.status;
    } catch (error) {
      dispatch(
        setShowToast({
          show: true,
          type: "error",
          toastMessage: "Something went wrong!",
        })
      );
    } finally {
      dispatch(decrementRequests());
    }
  };
}

export function getRfpResponseCheck(file_id: string) {
  return async (dispatch: AppDispatch) => {
    dispatch(incrementRequests());
    try {
      const path = `/rfp-response-check/${file_id}`;
      const response = await api.get(path);
      localStorage.setItem(`${file_id}`, `${response.data.is_rfp_response}`);
      dispatch(repResponseCheck(response.data));
    } catch (error) {
      dispatch(
        setShowToast({
          show: true,
          type: "error",
          toastMessage: "Something went wrong!",
        })
      );
    } finally {
      dispatch(decrementRequests());
    }
  };
}
