import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "redux/store";
import { api } from "api/axiosInterceptor";
import {
  decrementRequests,
  incrementRequests,
  setShowToast,
} from "./application";
import { IRfpState, RfpQuestion, RowData } from "types/rfp";

const initialState: IRfpState = {
  rfpQuestion: {
    rpf_question_extraction: [],
  },
};

const slice = createSlice({
  name: "overview",
  initialState,
  reducers: {
    rfpQuestionExtraction(state, action: PayloadAction<RfpQuestion>) {
      state.rfpQuestion = action.payload;
    },
  },
});

export const { rfpQuestionExtraction } = slice.actions;

export default slice.reducer;

export function getRfpQuestions() {
  return async (dispatch: AppDispatch) => {
    dispatch(incrementRequests());
    try {
      const path = `/rfp/question/extraction/`;
      const response = await api.get(path);
      dispatch(rfpQuestionExtraction(response.data));
      return response.status;
    } catch (error) {
      dispatch(
        setShowToast({
          show: true,
          type: "error",
          toastMessage: "Something went wrong!",
        })
      );
    } finally {
      dispatch(decrementRequests());
    }
  };
}

export function setRfpQuestions(data: RowData[]) {
  return async (dispatch: AppDispatch) => {
    dispatch(incrementRequests());
    try {
      const path = `/rfp/question/extraction/`;
      const response = await api.post(path, data);
      dispatch(rfpQuestionExtraction(response.data));
      if (response.status === 200) {
        dispatch(getRfpQuestions());
      }
      return response.status;
    } catch (error) {
      dispatch(
        setShowToast({
          show: true,
          type: "error",
          toastMessage: "Something went wrong!",
        })
      );
    } finally {
      dispatch(decrementRequests());
    }
  };
}
