import { FilterFilled } from "@ant-design/icons";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import CustomToggle from "style-guide/component/CustomToggle";
import Typography from "style-guide/component/Typography";
import { RowData } from "types/rfp";
import RequirementPopUp from "./CriticalPopUp";
import { setRfpQuestions } from "../../../../redux/slice/rfp";

const CriticalRequirement: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [isCriticalOpen, setIsCriticalOpen] = useState(false);
  const [activeRow, setActiveRow] = useState<RowData | null>(null);
  const [openDropdown, setOpenDropdown] = useState<
    "category" | "subcategory" | null
  >(null);
  const [selectedFilters, setSelectedFilters] = useState({
    category: "All",
    subcategory: "All",
  });

  const { rfpQuestion } = useSelector((state: RootState) => state.rfpData);
  const rows: RowData[] = rfpQuestion?.rpf_question_extraction || [];

  const categories = [
    "All",
    ...Array.from(new Set(rows.map((row) => row.category))),
  ];
  const subCategories = [
    "All",
    ...Array.from(new Set(rows.map((row) => row.sub_category))),
  ];

  const handleDropdownToggle = (key: "category" | "subcategory") => {
    setOpenDropdown((prev) => (prev === key ? null : key));
  };

  const handleFilterSelection = (
    key: "category" | "subcategory",
    value: string
  ) => {
    setSelectedFilters((prev) => ({ ...prev, [key]: value }));
    setOpenDropdown(null);
  };

  const filteredRows = rows.filter(
    (row) =>
      (selectedFilters.category === "All" ||
        row.category === selectedFilters.category) &&
      (selectedFilters.subcategory === "All" ||
        row.sub_category === selectedFilters.subcategory)
  );

  const openCriticalRequirement = (row: RowData) => {
    if (row?.is_critical === true) {
      const updatedRows = rows.map((rowItem) =>
        rowItem.question_number === row.question_number
          ? {
              ...rowItem,
              sample_answer: "",
              is_critical: false,
            }
          : rowItem
      );
      dispatch(setRfpQuestions(updatedRows));
    } else {
      setActiveRow(row);
      setIsCriticalOpen(true);
    }
  };

  return (
    <div className="w-full p-4">
      <table className="w-full mt-4 table-fixed">
        <colgroup>
          <col style={{ width: "6%" }} />
          <col style={{ width: "40%" }} />
          <col style={{ width: "18%" }} />
          <col style={{ width: "18%" }} />
          <col style={{ width: "14%" }} />
        </colgroup>
        <thead>
          <tr>
            {[
              "Number",
              "Requirement",
              "Category",
              "Sub-Category",
              "Critical requirement",
            ].map((header, index) => (
              <th
                key={index}
                className="p-4 bg-[#FAFAFA] border-r border-b text-left"
              >
                {header === "Category" || header === "Sub-Category" ? (
                  <div className="relative">
                    <div
                      className="flex justify-between items-center cursor-pointer"
                      onClick={() =>
                        handleDropdownToggle(
                          header === "Category" ? "category" : "subcategory"
                        )
                      }
                    >
                      <Typography
                        variant="Base"
                        text={header}
                        strong
                        className="w-fit"
                      />
                      <FilterFilled className="text-[#00000073] h-3 w-3" />
                    </div>
                    {openDropdown ===
                      (header === "Category" ? "category" : "subcategory") && (
                      <div className="absolute h-[350px] w-[200px] top-8 left-0 bg-white shadow-lg border rounded-md p-2 z-10 overflow-auto">
                        {(header === "Category"
                          ? categories
                          : subCategories
                        ).map((item) => (
                          <div
                            key={item}
                            className={`p-2 hover:bg-gray-100 hover:text-black cursor-pointer rounded-md text-left ${
                              selectedFilters[
                                header === "Category"
                                  ? "category"
                                  : "subcategory"
                              ] === item
                                ? "bg-blue-primary text-white"
                                : ""
                            }`}
                            onClick={() =>
                              handleFilterSelection(
                                header === "Category"
                                  ? "category"
                                  : "subcategory",
                                item
                              )
                            }
                          >
                            <Typography variant="Base" text={item} />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ) : (
                  <Typography
                    variant="Base"
                    text={header}
                    strong
                    className="w-fit"
                  />
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {filteredRows.length > 0 ? (
            filteredRows.map((row) => (
              <tr
                key={row.question_number}
                className="border-b border-[#F0F0F0] p-2 w-full"
              >
                <td className="p-4 whitespace-nowrap">
                  <Typography variant="Base" text={`${row.question_number}`} />
                </td>
                <td className="p-4 whitespace-nowrap">
                  <Typography variant="Base" text={row.question} />
                </td>
                <td className="p-4 whitespace-nowrap">
                  <Typography variant="Base" text={row.category} />
                </td>
                <td className="p-4 whitespace-nowrap">
                  <Typography variant="Base" text={row.sub_category} />
                </td>
                <td className="p-4 whitespace-nowrap">
                  <CustomToggle
                    defaultChecked={row.is_critical === true}
                    onChange={() => openCriticalRequirement(row)}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5} className="p-4 text-center">
                <Typography variant="Base" text="No data found" />
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {isCriticalOpen && (
        <RequirementPopUp
          setActiveRow={setActiveRow}
          activeRow={activeRow}
          setIsCriticalOpen={setIsCriticalOpen}
          rows={rows}
        />
      )}
    </div>
  );
};

export default CriticalRequirement;
