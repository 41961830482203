import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "redux/store";
import Button from "style-guide/component/Button";
import Loading from "style-guide/component/Loader";
import { IFile } from "types/overview";
import UploadDocument from "components/intake/rfp_free/overview/UploadDocument";

interface RfpUploadProps {
  openSelectedStep: (step: string) => void;
}

const RfpUpload: React.FC<RfpUploadProps> = ({ openSelectedStep }) => {
  const navigate = useNavigate();

  const [uploadedFiles, setUploadedFiles] = useState<IFile[]>([]);

  const { isLoading, isLoadingModal } = useSelector(
    (state: RootState) => state.applicationData
  );

  const handleBack = () => {
    navigate(-1);
  };

  const handleNext = () => {
    openSelectedStep("invite");
  };

  return (
    <>
      {(isLoading || isLoadingModal) && (
        <div className="fixed inset-0 flex items-center justify-center z-[9999]">
          <Loading
            mainHeading={isLoadingModal ? "Analyzing your File" : undefined}
          />
        </div>
      )}

      {/* Dynamic Component */}
      <div className="flex-grow">
        <UploadDocument
          setUploadedFiles={setUploadedFiles}
          uploadedFiles={uploadedFiles}
        />
      </div>

      {/* Footer Section */}
      <div className="h-[72px] fixed bottom-0 left-0 right-0 flex flex-row justify-between items-center bg-white p-4 border-t-[#D9D9D9] border-t-[1px]">
        <Button
          type="Default"
          size="Large"
          buttonText="Back"
          onClick={handleBack}
        />
        <Button
          type="Primary"
          size="Large"
          buttonText="Next"
          onClick={handleNext}
        />
      </div>
    </>
  );
};

export default RfpUpload;
