import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Typography from "style-guide/component/Typography";
import ProgressBar from "style-guide/component/Progressbar";
import Button from "style-guide/component/Button";
import Menu from "style-guide/component/Menu";
import CategoryTemplate from "./CategoryTemplate";
import CriteriaTemplate from "./CriteriaTemplate";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import {
  generateCriteria,
  generateSections,
  getCoreBusinessCriteria,
  getCoreBusinessSection,
} from "../../../../redux/slice/build";
import Loading from "style-guide/component/Loader";
import { setShowToast } from "../../../../redux/slice/application";

const Template: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  const [currentStep, setCurrentStep] = useState<number>(2);
  const [sections, setSections] = useState<string[]>([]);
  const [category, setCategory] = useState<
    { section: string; criteria: string[] }[]
  >([]);
  const [isCriteriaLoading, setIsCriteriaLoading] = useState<boolean>(false);
  const { isLoading, isLoadingModal } = useSelector(
    (state: RootState) => state.applicationData
  );

  const queryParams = new URLSearchParams(location.search);
  const initialComponent =
    queryParams.get("component") === "criteria" ? "criteria" : "category";
  const [currentComponent, setCurrentComponent] = useState<
    "category" | "criteria"
  >(initialComponent);

  useEffect(() => {
    const fetchData = async () => {
      setIsCriteriaLoading(currentComponent === "criteria");
      try {
        if (currentComponent === "category") {
          await dispatch(getCoreBusinessSection());
        } else {
          await dispatch(getCoreBusinessCriteria());
        }
      } catch {
        dispatch(
          setShowToast({
            show: true,
            type: "error",
            toastMessage: "Something went wrong!",
          })
        );
      } finally {
        setIsCriteriaLoading(false);
      }
    };

    fetchData();
  }, [currentComponent, dispatch]);

  const handleNext = async () => {
    if (currentComponent === "category") {
      setIsCriteriaLoading(true);
      try {
        const response = await dispatch(generateSections(sections));
        if (response === 200) {
          setCurrentComponent("criteria");
          navigate("/build_scratch/template/?component=criteria");
        }
      } catch (error) {
        dispatch(
          setShowToast({
            show: true,
            type: "error",
            toastMessage: "Something went wrong!",
          })
        );
      } finally {
        setIsCriteriaLoading(false);
      }
    } else {
      try {
        const response = await dispatch(generateCriteria(category));
        if (response === 200) {
          navigate(
            "/build_scratch/requirements_criteira/?component=generateRequirements"
          );
        }
      } catch {
        dispatch(
          setShowToast({
            show: true,
            type: "error",
            toastMessage: "Something went wrong!",
          })
        );
      }
    }
  };

  const handleBack = () => {
    if (currentComponent === "category") {
      navigate("/build_scratch/overview/?component=parseDocument");
      setCurrentStep(1);
    } else {
      setCurrentComponent("category");
      navigate("/build_scratch/template/?component=category");
    }
  };

  const renderContent = () =>
    currentComponent === "category" ? (
      <CategoryTemplate setSections={setSections} sections={sections} />
    ) : (
      <CriteriaTemplate setCategory={setCategory} category={category} />
    );

  return (
    <div className="overflow-x-auto w-full min-h-screen pb-[100px] py-8 px-16 flex flex-col gap-8">
      <div className="flex flex-row justify-between">
        <Typography
          text="Nozma"
          variant="LG"
          className="text-blue-primary"
          strong
        />
        <Menu />
      </div>
      <ProgressBar currentStep={currentStep} />
      {renderContent()}
      {(isLoadingModal || isLoading || isCriteriaLoading) && (
        <div className="fixed inset-0 flex items-center justify-center z-[9999] text-center">
          <Loading
            mainHeading={
              isLoadingModal
                ? "Generating key sections to structure your evaluation."
                : isCriteriaLoading
                  ? "Building criteria tailored to your business needs."
                  : undefined
            }
            headingText={
              isLoadingModal
                ? "This will only take a few seconds."
                : isCriteriaLoading
                  ? "Please wait a moment."
                  : undefined
            }
          />
        </div>
      )}

      <div className="h-[72px] fixed bottom-0 left-0 right-0 flex justify-between items-center bg-white p-4 border-t border-gray-300">
        <Button
          type="Default"
          size="Large"
          icon={false}
          buttonText="Back"
          onClick={handleBack}
        />
        <Button
          type={isLoading ? "Disabled" : "Primary"}
          size="Large"
          icon={false}
          buttonText="Next"
          onClick={handleNext}
        />
      </div>
    </div>
  );
};

export default Template;
