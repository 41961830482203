import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import Breadcrumb from "style-guide/component/Breadcrumb";
import Typography from "style-guide/component/Typography";
import QuestionProgress from "style-guide/component/QuestionProgress";
import Menu from "style-guide/component/Menu";
import ProgressBarComponent from "style-guide/component/Progress";
import NewQuestion from "./question";
import { useWebSocket } from "api/websocket";
import { setShowError } from "../../../../redux/slice/application";
import { getRefinementQuestion } from "../../../../redux/slice/build";
import { useSearchParams } from "react-router-dom";
import Loading from "style-guide/component/Loader";

const Refine = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { message, clearMessage } = useWebSocket();
  const [searchParams, setSearchParams] = useSearchParams();

  const initialQuestionIndex =
    Number(searchParams.get("currentQuestion")) ||
    Number(localStorage.getItem("currentQuestion")) ||
    0;

  const [currentQuestion, setCurrentQuestion] =
    useState<number>(initialQuestionIndex);
  const { refinementQuestion } = useSelector(
    (state: RootState) => state.buildData
  );

  const { isLoading } = useSelector(
    (state: RootState) => state.applicationData
  );

  useEffect(() => {
    clearMessage();
    const maxAttempts = 5;
    let attempts = 0;

    const fetchRefinementQuestion = async () => {
      try {
        attempts++;
        const response = await dispatch(getRefinementQuestion());
        if (response !== 500 && response !== 502 && response !== 400) {
          return;
        }

        if (attempts < maxAttempts) {
          await fetchRefinementQuestion();
          dispatch(setShowError({ showError: false }));
        } else {
          dispatch(setShowError({ showError: true }));
        }
      } catch (error) {
        if (attempts < maxAttempts) {
          await fetchRefinementQuestion();
        } else {
          dispatch(setShowError({ showError: true }));
        }
      }
    };

    fetchRefinementQuestion();
  }, []);

  return (
    <div className="overflow-x-auto w-full min-h-screen pb-[100px] pt-6 px-6 md:px-12 font-helvetica-neue flex flex-col gap-4">
      <div className="flex flex-row justify-between">
        <Typography
          text="Nozma"
          variant="LG"
          className="text-blue-primary"
          strong
        />
        <Menu />
      </div>
      <Breadcrumb
        items={[
          {
            label: "Evaluation Strategy",
            link: "",
            isActive: false,
          },
          { label: "Refine Requirements", link: "/", isActive: true },
        ]}
        theme="light"
      />

      <QuestionProgress
        value={currentQuestion + 1}
        max={refinementQuestion.length}
      />

      {currentQuestion === 0 && (
        <div className="flex flex-col gap-2">
          <Typography
            variant="Heading 3"
            text="To better tailor the evaluation to your specific use case, we have a few additional questions for you."
          />
          <Typography
            variant="LG"
            text="Your responses will help us generate a targeted evaluation strategy that aligns with your unique needs."
          />
        </div>
      )}

      {isLoading ? (
        <>
          {message?.info?.type === 1 ? (
            <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50">
              <ProgressBarComponent
                steps={message?.task_list}
                currentStatus={message?.status_id}
                progress={message?.info?.percentage || 0}
                progressHeading={message?.info?.progressbar}
              />
            </div>
          ) : message?.info?.type === 0 ? (
            <div className="fixed inset-0 flex items-center justify-center z-[9999] text-center">
              <Loading mainHeading="Generating the Refinement Questions"/>
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        <NewQuestion
          refine={refinementQuestion}
          searchParams={searchParams}
          setCurrentQuestion={setCurrentQuestion}
          currentQuestion={currentQuestion}
          setSearchParams={setSearchParams}
        />
      )}
    </div>
  );
};

export default Refine;
