import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setIntakeObjectives } from "../../../../redux/slice/overview";
import { AppDispatch, RootState } from "redux/store";
import UploadDocument from "./UploadDocument";
import Button from "style-guide/component/Button";
import ReviewDocument from "./ReviewDocument";
import Loading from "style-guide/component/Loader";
import { IFile } from "types/overview";

interface OverviewProps {
  openSelectedStep: (step: string) => void;
}

const ProjectOverview: React.FC<OverviewProps> = ({ openSelectedStep }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const [uploadedFiles, setUploadedFiles] = useState<IFile[]>([]);
  const [objectives, setObjectives] = useState<string[]>([]);
  const [editableSolution, setEditableSolution] = useState<string>("");
  const [currentComponent, setCurrentComponent] = useState<string | null>();
  const intakeMark: string | null = localStorage.getItem("intake");

  const { isLoading, isLoadingModal } = useSelector(
    (state: RootState) => state.applicationData
  );

  useEffect(() => {
    if (intakeMark) {
      setCurrentComponent(intakeMark);
    }
  }, [intakeMark]);

  const handleBack = () => {
    if (currentComponent === "overview") {
      navigate("/choose_RFP");
    } else {
      openSelectedStep("overview");
    }
  };

  const handleNext = async () => {
    if (currentComponent === "overview") {
      if (uploadedFiles.length > 0) {
        openSelectedStep("review");
      }
    } else {
      const response = await dispatch(
        setIntakeObjectives({ objectives, solution: editableSolution })
      );
      if (response === 200) {
        openSelectedStep("template");
        openSelectedStep("requirement");
      }
    }
  };

  return (
    <>
      {(isLoading || isLoadingModal) && (
        <div className="fixed inset-0 flex items-center justify-center z-[9999]">
          <Loading
            mainHeading={isLoadingModal ? "Analyzing your File" : undefined}
          />
        </div>
      )}

      {/* Dynamic Component */}
      <div className="flex-grow">
        {currentComponent === "overview" ? (
          <UploadDocument
            setUploadedFiles={setUploadedFiles}
            uploadedFiles={uploadedFiles}
          />
        ) : currentComponent === "review" ? (
          <ReviewDocument
            objectives={objectives}
            setObjectives={setObjectives}
            editableSolution={editableSolution}
            setEditableSolution={setEditableSolution}
          />
        ) : (
          ""
        )}
      </div>

      {/* Footer Section */}
      <div className="h-[72px] fixed bottom-0 left-0 right-0 flex flex-row justify-between items-center bg-white p-4 border-t-[#D9D9D9] border-t-[1px]">
        <Button
          type="Default"
          size="Large"
          buttonText="Back"
          onClick={handleBack}
        />
        <Button
          type={
            isLoading ||
            (currentComponent === "upload" && uploadedFiles.length === 0)
              ? "Disabled"
              : "Primary"
          }
          size="Large"
          buttonText="Next"
          onClick={handleNext}
        />
      </div>
    </>
  );
};

export default ProjectOverview;
