import React from "react";
import Typography from "./Typography";
import { CloseCircleFilled, CloseOutlined } from "@ant-design/icons";
import Button from "./Button";
import { useDispatch, useSelector } from "react-redux";
import { setShowError } from "../../redux/slice/application";
import { RootState } from "redux/store";
import { useNavigate } from "react-router-dom";

interface ErrorModalProps {
  headingText?: string;
  subText?: string;
}

const ErrorModal: React.FC<ErrorModalProps> = ({
  headingText = "Something went wrong.",
  subText = "Please try again. ",
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showError } = useSelector(
    (state: RootState) => state.applicationData
  );

  const handleRetry = () => {
    window.location.reload();
    dispatch(setShowError(false));
  };

  const handleBack = () => {
    navigate(-1);
    dispatch(setShowError(false));
  };

  const handleClose = () => {
    dispatch(setShowError(false));
  };

  if (!showError) {
    return null;
  }

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-[9999]">
      <div className="relative flex flex-col justify-between items-center p-4 sm:p-8 gap-8 w-[80%] sm:w-[26%] rounded-lg shadow-md border bg-white">
        {/* Close Icon Positioned at Top-Right */}
        <CloseOutlined
          onClick={handleClose}
          className="absolute top-3 right-4 text-gray-500 hover:text-black cursor-pointer"
        />
        <Typography variant="Heading 3" text="Importing your data" />
        <CloseCircleFilled className="text-[red]" />
        <div className="flex flex-col w-full bg-[#F0F5FF] items-center py-4 gap-2">
          <Typography variant="Heading 5" text={headingText} strong />
          <Typography variant="SM" text={subText} />
        </div>
        <div className="flex gap-4">
          <Button type="Default" buttonText="Back" onClick={handleBack} />
          <Button type="Primary" buttonText="Retry" onClick={handleRetry} />
        </div>
      </div>
    </div>
  );
};

export default ErrorModal;
