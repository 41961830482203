import React, { useEffect, useState } from "react";
import Button from "style-guide/component/Button";
import Typography from "style-guide/component/Typography";
import Input from "style-guide/component/Input";
import { DeleteOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import { getSolutionAndObjective } from "../../../../redux/slice/overview";
import TextArea from "style-guide/component/Textarea";

interface ReviewDocumentProps {
  objectives: string[];
  setObjectives: React.Dispatch<React.SetStateAction<string[]>>;
  editableSolution: string;
  setEditableSolution: React.Dispatch<React.SetStateAction<string>>;
}
const ReviewDocument: React.FC<ReviewDocumentProps> = ({
  objectives,
  setObjectives,
  editableSolution,
  setEditableSolution,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [newObjective, setNewObjective] = useState<string>("");
  const { solutionAndObjective } = useSelector(
    (state: RootState) => state.overviewData
  );

  useEffect(() => {
    const fetchCriteriaRequirement = async () => {
      const response = await dispatch(getSolutionAndObjective());
      if (response === 500 || response === 502) {
        dispatch(getSolutionAndObjective());
      }
    };
    fetchCriteriaRequirement();
  }, []);

  useEffect(() => {
    if (solutionAndObjective?.objectives?.length) {
      setObjectives(solutionAndObjective.objectives);
    }
    if (solutionAndObjective?.solution) {
      setEditableSolution(solutionAndObjective.solution);
    }
  }, [solutionAndObjective, setEditableSolution, setObjectives]);

  const handleAddObjective = () => {
    if (newObjective.trim()) {
      setObjectives([...objectives, newObjective]);
      setNewObjective("");
    }
  };

  return (
    <>
      {/* Instruction Section */}
      <div className="gap-2 flex flex-col justify-between p-4">
        <Typography
          text="We’ve parsed your documents. Please review the details below."
          variant="Heading 3"
          className="text-black"
        />
        <Typography
          text="Take a moment to make sure everything looks correct. This will help us create an accurate evaluation template and personalized recommendations for you."
          variant="LG"
          className="text-black"
        />
      </div>

      <div className="flex flex-col gap-2">
        {/* Solution Type */}
        <div className="gap-2 flex flex-col p-4">
          <Typography
            text="What is the core business problem that you need to solve?"
            variant="Heading 4"
            className="text-black"
          />
          <TextArea
            value={editableSolution}
            readOnly
            size="Large"
            className="text-sm w-full sm:w-[300px] md:w-[400px] lg:w-[650px]"
          />
        </div>

        {/* Objectives Section */}
        <div className="gap-2 flex flex-col p-4">
          <Typography
            text="What are the main objectives you’re hoping to achieve with this solution?"
            variant="Heading 4"
            className="text-black"
          />
          <div className="flex flex-col sm:flex-row gap-4">
            <Input
              placeholder="Write an objective here"
              size="Medium"
              theme="light"
              className="w-full sm:w-[300px] md:w-[400px] lg:w-[650px]"
              value={newObjective}
              onChange={(e) => setNewObjective(e.target.value)}
            />
            <Button
              type={newObjective ? "Default" : "Disabled"}
              size="Medium"
              buttonText="Add"
              onClick={handleAddObjective}
            />
          </div>

          {/* Display existing and new objectives */}
          <div className="flex flex-col gap-2">
            {objectives.map((objective, index) => (
              <div key={index} className="flex flex-row gap-4">
                <div className="border border-gray-300 p-2 rounded-lg w-full sm:w-[300px] md:w-[400px] lg:w-[650px]">
                  <Typography text={objective} variant="Base" />
                </div>
                <Button
                  type="Default"
                  size="Medium"
                  icon
                  className="border-[1px] border-[#D9D9D9] text-black"
                  iconComponent={<DeleteOutlined />}
                  onClick={() =>
                    setObjectives(objectives.filter((_, i) => i !== index))
                  }
                />
              </div>
            ))}
          </div>
        </div>

        {/* Suggestion */}
        {/* <div className="gap-2 flex flex-col p-4">
          <Typography
            text="Suggestions"
            variant="Base"
            strong
            className="text-black"
          />
          <div className="flex flex-row gap-2 border-[1px] border-dashed border-blue-primary p-2 rounded-md w-full sm:w-[300px] md:w-[400px] lg:w-[650px]">
            <PlusOutlined />
            <Typography
              text="Efficiency and scalability: Reduction of manual effort and errors"
              variant="Base"
            />
          </div>
          <Button
            type="Text"
            buttonText="More suggestions"
            icon
            iconComponent={<BulbOutlined />}
            size="Small"
            className="w-[fit-content]"
          />
        </div> */}
      </div>
    </>
  );
};

export default ReviewDocument;
