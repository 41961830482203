import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "redux/store";
import Loading from "style-guide/component/Loader";
import Typography from "style-guide/component/Typography";
import Button from "style-guide/component/Button";
import InviteStakeholder from "./Invite";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

interface InviteProps {
  openSelectedStep: (step: string) => void;
}

const Invite: React.FC<InviteProps> = ({ openSelectedStep }) => {
  const navigate = useNavigate();
  const [Finish, setFinish] = useState<boolean>(false);
  const pathName = window.location.pathname;

  const { isLoading } = useSelector(
    (state: RootState) => state.applicationData
  );

  const handleBack = () => {
    if (pathName === "/rfp_defined") {
      openSelectedStep("overview");
    } else {
      openSelectedStep("upload");
    }
  };

  const handleNext = async () => {
    if (pathName === "/rfp_defined") {
      openSelectedStep("requirement");
    } else {
      setTimeout(() => {
        setFinish(false);
        navigate("/evaluation");
      }, 3000);
      setFinish(true);
    }
  };

  return (
    <>
      {Finish ? (
        <div className="fixed inset-0 flex items-center justify-center flex-col gap-2">
          <Spin indicator={<LoadingOutlined spin />} size="large" />
          <Typography
            text="Customizing your evaluation space..."
            variant="XS"
          />
        </div>
      ) : (
        <>
          {/* <ProgressBar currentStep={currentStep} /> */}
          {isLoading && (
            <div className="fixed inset-0 flex items-center justify-center z-[9999]">
              <Loading />
            </div>
          )}

          <div className="flex-grow">
            <InviteStakeholder />
          </div>

          <div className="h-[72px] fixed bottom-0 left-0 right-0 flex flex-row justify-between items-center bg-white p-4 border-t-[#D9D9D9] border-t-[1px]">
            <Button
              type="Default"
              size="Large"
              icon={false}
              buttonText="Back"
              onClick={handleBack}
            />
            <Button
              type="Primary"
              size="Large"
              icon={false}
              buttonText={pathName === "/free_from_rfp" ? "Finish" : "Next"}
              onClick={handleNext}
            />
          </div>
        </>
      )}
    </>
  );
};

export default Invite;
