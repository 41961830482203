import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Typography from "style-guide/component/Typography";
import ProgressBar from "style-guide/component/Progressbar";
import Button from "style-guide/component/Button";
import Menu from "style-guide/component/Menu";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import GenerateRequirements from "./GenerateRequirement";
import { ChipsData } from "types/templates";
import Requirements from "./Requirement";
import {
  downloadRequirements,
  getRequirements,
} from "../../../../redux/slice/build";
import { useWebSocket } from "api/websocket";
import ProgressBarComponent from "style-guide/component/Progress";
import Loading from "style-guide/component/Loader";

const RequirementFlow: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { message } = useWebSocket();
  const dispatch = useDispatch<AppDispatch>();
  const [currentStep, setCurrentStep] = useState<number>(2);
  const [chipsData, setChipsData] = useState<ChipsData>({});
  const { isLoading } = useSelector(
    (state: RootState) => state.applicationData
  );
  const { requirementsResponse } = useSelector(
    (state: RootState) => state.buildData
  );

  const queryParams = new URLSearchParams(location.search);
  const initialComponent =
    (queryParams.get("component") as
      | "generateRequirements"
      | "add_requirement") || "generateRequirements";

  const [currentComponent, setCurrentComponent] = useState<
    "generateRequirements" | "add_requirement"
  >(initialComponent);

  const handleNext = async () => {
    if (currentComponent === "generateRequirements") {
      navigate(
        "/build_scratch/requirements_criteira/?component=add_requirement"
      );
      setCurrentComponent("add_requirement");
    } else {
      dispatch(downloadRequirements());
    }
  };

  const handleBack = () => {
    if (currentComponent === "generateRequirements") {
      navigate("/build_scratch/template/?component=criteria");
      setCurrentStep(2);
    } else {
      setCurrentComponent("generateRequirements");
      navigate(
        "/build_scratch/requirements_criteira/?component=generateRequirements"
      );
    }
  };

  useEffect(() => {
    dispatch(getRequirements());
  }, []);

  return (
    <div className="overflow-x-auto w-full min-h-screen pb-[100px] py-8 px-16 flex flex-col gap-8">
      {/* Header Section */}
      <div className="flex flex-row justify-between">
        <Typography
          text="Nozma"
          variant="LG"
          className="text-blue-primary"
          strong
        />
        <Menu />
      </div>
      <ProgressBar currentStep={currentStep} />

      {isLoading ? (
        <>
          {message?.info?.type === 1 ? (
            <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50">
              <ProgressBarComponent
                steps={message?.task_list}
                currentStatus={message?.status_id}
                progress={message?.info?.percentage || 0}
                progressHeading="Generating Requirements for Each Sections"
              />
            </div>
          ) : message?.info?.type === 0 ? (
            <div className="fixed inset-0 flex items-center justify-center z-[9999] text-center">
              <Loading />
            </div>
          ) : (
            ""
          )}
        </>
      ) : (
        <>
          {currentComponent === "generateRequirements" && (
            <GenerateRequirements />
          )}
          {currentComponent === "add_requirement" && (
            <Requirements
              currentComponent={currentComponent}
              chipsData={chipsData}
              setChipsData={setChipsData}
            />
          )}
        </>
      )}

      {/* Footer Section */}
      <div className="h-[72px] fixed bottom-0 left-0 right-0 flex justify-between items-center bg-white p-4 border-t border-gray-300">
        <Button
          type="Default"
          size="Large"
          icon={false}
          buttonText="Back"
          onClick={handleBack}
        />
        <div className="flex flex-row gap-4">
          <Button
            type={
              isLoading
                ? "Disabled"
                : currentComponent === "add_requirement"
                  ? "Default"
                  : "Primary"
            }
            size="Large"
            icon={false}
            buttonText={
              currentComponent === "add_requirement" ? "Download" : "Next"
            }
            onClick={handleNext}
          />
          {currentComponent === "add_requirement" &&
            !requirementsResponse.is_refined && (
              <Button
                type={isLoading ? "Disabled" : "Primary"}
                size="Large"
                icon={false}
                buttonText="Refine"
                onClick={() => navigate("/eval")}
              />
            )}
        </div>
      </div>
    </div>
  );
};

export default RequirementFlow;
