import React, { useEffect } from "react";
import Typography from "style-guide/component/Typography";
import TextArea from "style-guide/component/Textarea";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import { getBusinessProblemQueries } from "../../../../redux/slice/build";
import { Query } from "types/build";

interface CoreBusinessProps {
  error: string;
  solution: string;
  setSolution: React.Dispatch<React.SetStateAction<string>>;
  setError: React.Dispatch<React.SetStateAction<string>>;
  question: Query[];
  answers: Query[];
  setAnswers: React.Dispatch<React.SetStateAction<Query[]>>;
}

const CoreBusiness: React.FC<CoreBusinessProps> = ({
  setSolution,
  solution,
  error,
  setError,
  question,
  answers,
  setAnswers,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const { businessProblemQuestion } = useSelector(
    (state: RootState) => state.buildData
  );

  useEffect(() => {
    dispatch(getBusinessProblemQueries());
  }, []);

  useEffect(() => {
    if (businessProblemQuestion?.solution) {
      setSolution(businessProblemQuestion.solution);
    }
  }, [businessProblemQuestion]);

  useEffect(() => {
    if (businessProblemQuestion?.queries?.length > 0) {
      setAnswers(
        businessProblemQuestion?.queries?.map((item: Query) => ({
          question: item.question,
          suggestion: item.suggestion,
          answer: item.answer,
        }))
      );
    }
  }, [businessProblemQuestion, answers.length]);

  const handleInputChange = (value: string, index: number) => {
    setError("");
    const updatedAnswers = answers.map((item, idx) =>
      idx === index ? { ...item, answer: value } : item
    );
    setAnswers(updatedAnswers);
    const combinedSolution = updatedAnswers
      .map((item) => item.answer)
      .filter((val) => val?.trim() !== "")
      .join(" ");
    setSolution(combinedSolution);
  };

  return (
    <>
      <div className="gap-2 flex flex-col justify-between p-4">
        <Typography
          text="What business problem are you trying to solve?"
          variant="Heading 3"
          className="text-black"
        />
        <Typography
          text="Describe it briefly to help tailor your evaluation template."
          variant="LG"
          className="text-black"
        />
      </div>

      <div className="flex flex-col md:flex-row w-full gap-8 p-4">
        {/* Left Section - 50% */}
        <div className="w-full md:w-1/2 flex flex-col gap-4">
          {question?.length > 0 &&
            question.map((item, index) => (
              <div key={index} className="flex flex-col gap-2">
                <Typography variant="Heading 4" text={item?.question} />
                <TextArea
                  value={answers[index]?.answer}
                  placeholder={item?.suggestion}
                  size="Large"
                  onChange={(e) => handleInputChange(e.target.value, index)}
                  className="text-sm w-full h-[130px]"
                />
              </div>
            ))}
        </div>

        {/* Right Section - 50% */}
        <div className="w-full md:w-1/2 flex p-8 flex-col gap-2 bg-[#F5F5F5] h-fit rounded-md">
          <div className="bg-[#F6FFED] border-[#B7EB8F] border-[1px] px-2 py-[1px] w-fit rounded-md">
            <Typography
              variant="SM"
              text="Your template will be populated here"
              className="text-[#237804]"
            />
          </div>
          <Typography
            text="What is the core business problem that you need to solve?"
            variant="Heading 4"
            className="text-black"
          />
          <TextArea
            value={solution}
            placeholder="Your template"
            size="Large"
            readOnly
            className="text-sm w-full focus-none h-[400px]"
          />
          {error && (
            <Typography
              variant="Base"
              text={error}
              className="text-[red]"
              strong
            />
          )}
        </div>
      </div>
    </>
  );
};

export default CoreBusiness;
