import clsx from "clsx";
import React, { useState, ChangeEvent, useRef, useEffect } from "react";

type TextAreaProps = {
  placeholder?: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  state?: "Default" | "Hover" | "Focused";
  size?: "Small" | "Medium" | "Large";
  disabled?: boolean;
  theme?: "light" | "dark";
  className?: string;
  readOnly?: boolean;
};

const stateClasses = {
  light: {
    Default: "border-gray-300 bg-white text-black",
    Hover: "border-[#3E67A3] bg-white text-black",
    Focused:
      "border-[#0F428D] border-[2px] bg-white text-black shadow-[0px_0px_0px_2px_#0591FF1A]",
    Disabled: "border-gray-300 bg-gray-200 text-gray-500 cursor-not-allowed",
  },
  dark: {
    Default: "border-[#424242] bg-[#424242] text-white",
    Hover: "border-[#97BDF4] bg-[#424242] text-white",
    Focused:
      "border-[#7EADF2] bg-[#424242] border-[2px] text-white shadow-[0px_0px_0px_2px_#0591FF1A]",
    Disabled: "border-gray-600 bg-[#424242] text-gray-500 cursor-not-allowed",
  },
};

const sizeClasses = {
  Small: "text-sm p-1 rounded-md",
  Medium: "text-md p-2 rounded-md",
  Large: "text-lg p-2 rounded-lg",
};

const TextArea: React.FC<TextAreaProps> = ({
  placeholder = "",
  value = "",
  onChange,
  state = "Default",
  size = "Medium",
  disabled = false,
  theme = "light",
  className = " ",
  readOnly = false,
}) => {
  const [textAreaState, setTextAreaState] = useState<
    "Default" | "Hover" | "Focused"
  >(state);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const adjustHeight = () => {
    if (textAreaRef.current) {
      const hasHeight = className.includes("h-");
      if (!hasHeight) {
        textAreaRef.current.style.height = "auto";
        textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
      }
    }
  };

  useEffect(() => {
    adjustHeight();
  }, [value]);

  const handleFocus = () =>
    !disabled && !readOnly && setTextAreaState("Focused");
  const handleBlur = () =>
    !disabled && !readOnly && setTextAreaState("Default");

  const handleMouseEnter = () => {
    if (!disabled && !readOnly && textAreaState !== "Focused")
      setTextAreaState("Hover");
  };

  const handleMouseLeave = () => {
    if (!disabled && !readOnly && textAreaState !== "Focused")
      setTextAreaState("Default");
  };

  return (
    <textarea
      ref={textAreaRef}
      value={value}
      onChange={(e) => {
        if (!readOnly) {
          adjustHeight();
          onChange && onChange(e);
        }
      }}
      placeholder={placeholder}
      className={clsx(
        `transition border w-full resize-none outline-none ${
          disabled
            ? stateClasses[theme].Disabled
            : stateClasses[theme][textAreaState]
        } ${sizeClasses[size]}`,
        className
      )}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      disabled={disabled || readOnly}
      readOnly={readOnly}
      style={{
        overflow: "hidden",
        height: className.includes("h-") ? undefined : "fit-content", // Default height fallback
      }}
    />
  );
};

export default TextArea;
