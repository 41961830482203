import React from "react";

interface TooltipProps {
  text: string;
  position?: "top" | "bottom" | "left" | "right";
  visible?: boolean; // Control visibility
  children: React.ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({
  text,
  position = "top",
  visible = true,
  children,
}) => {
  return (
    <div className={`relative inline-block ${visible ? "group" : ""}`}>
      {visible && (
        <div
          className={`mx-8 sm:w-52 max-w-xs absolute invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-gray-900 text-white text-sm px-4 py-2 rounded-lg shadow-lg z-10 whitespace-normal
          ${
            position === "top"
              ? "bottom-full left-1/2 transform -translate-x-1/2 mb-2"
              : ""
          }
          ${
            position === "bottom"
              ? "top-full left-1/2 transform -translate-x-1/2 mt-2"
              : ""
          }
          ${
            position === "left"
              ? "right-full top-1/2 transform -translate-y-1/2 mr-2"
              : ""
          }
          ${
            position === "right"
              ? "left-full top-1/2 transform -translate-y-1/2 ml-2"
              : ""
          }
          `}
        >
          <div className="relative">
            <div
              className={`absolute w-3 h-3 bg-gray-900 transform rotate-45 z-[-1]
              ${
                position === "top"
                  ? "bottom-[-6px] left-1/2 transform -translate-x-1/2"
                  : ""
              }
              ${
                position === "bottom"
                  ? "top-[-6px] left-1/2 transform -translate-x-1/2"
                  : ""
              }
              ${
                position === "left"
                  ? "right-[-6px] top-1/2 transform -translate-y-1/2"
                  : ""
              }
              ${
                position === "right"
                  ? "left-[-6px] top-1/2 transform -translate-y-1/2"
                  : ""
              }`}
            />
            {text}
          </div>
        </div>
      )}

      {/* Tooltip Trigger */}
      {children}
    </div>
  );
};

export default Tooltip;
