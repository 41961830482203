import {
  ArrowLeftOutlined,
  BarChartOutlined,
  CaretDownOutlined,
  CaretRightOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  FileTextOutlined,
  PlusOutlined,
  SearchOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import Button from "style-guide/component/Button";
import Typography from "style-guide/component/Typography";
import { BsArrowsCollapse } from "react-icons/bs";
import { MdExpand } from "react-icons/md";
import Input from "style-guide/component/Input";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import { getFileList } from "../../redux/slice/overview";
import {
  rfpResponseEvaluation,
  rfpResponseScore,
} from "../../redux/slice/evaluation";
import { getCriteriaRquirements } from "../../redux/slice/templates";
import Loading from "style-guide/component/Loader";
import { setShowToast } from "../../redux/slice/application";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Category,
  Criterion,
  EvaluationStats,
  IEvalResponse,
  IEvalScores,
} from "types/evaluation";
import ProgressBarComponent from "style-guide/component/Progress";
import { useWebSocket } from "api/websocket";

const EvaluationScorecard: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { message } = useWebSocket();
  const dispatch = useDispatch<AppDispatch>();
  const [expandedCriteria, setExpandedCriteria] = useState<string[]>([]);
  const [isEval, setIsEval] = useState<boolean>(false);
  const [evaluationData, setEvaluationData] = useState<Category[]>([]);
  const [evaluationStats, setEvaluationStats] = useState<EvaluationStats>({
    totalAnswers: 0,
    actualAnswers: 0,
    totalScored: 0,
    actualScored: 0,
    totalApproved: 0,
  });

  const { listOfFiles } = useSelector((state: RootState) => state.overviewData);
  const { evalData, evalScores } = useSelector(
    (state: RootState) => state.evaluationData
  );
  const { criteria } = useSelector((state: RootState) => state.templatesData);
  const { isLoading } = useSelector(
    (state: RootState) => state.applicationData
  );
  const searchParams = new URLSearchParams(location.search);
  const scoreCheck = searchParams.has("generateScore");

  useEffect(() => {
    const fetchCriteriaRequirement = async () => {
      const response = await dispatch(getCriteriaRquirements());
      if (response === 500 || response === 502) {
        dispatch(getCriteriaRquirements());
      } else if (response === 200) {
        setIsEval(true);
      }
    };

    const fetchFile = async () => {
      const response = await dispatch(getFileList("RFP_Response"));

      if (response === 200) {
        fetchCriteriaRequirement();
      }
    };

    fetchFile();
  }, []);

  useEffect(() => {
    if (listOfFiles?.files?.length > 0 && isEval) {
      const validFiles = listOfFiles.files
        .filter((file) => file.rfp_response?.company_name)
        .map((file) => file?.rfp_response?.company_name as string);

      const maxAttempts = 5;
      let attempts = 0;

      const fetchResponseEval = async () => {
        attempts++;
        const response = await dispatch(rfpResponseEvaluation(validFiles));

        if (response === 500 || response === 502 || response === 400) {
          if (attempts < maxAttempts) {
            await fetchResponseEval();
          } else {
            dispatch(
              setShowToast({
                show: true,
                type: "error",
                toastMessage:
                  "Maximum retry attempts reached. Please try again later!",
              })
            );
          }
        }
      };

      fetchResponseEval();
    }
  }, [isEval, listOfFiles]);

  const formatEvaluationData = (
    responseData: IEvalResponse,
    scores: IEvalScores
  ) => {
    const companies = Object.keys(responseData);
    const evaluationData: Category[] = [];
    let totalCriteriaAnswered = 0;
    let actualCriteriaAnswered = 0;
    let totalCriteriaScored = 0;
    let actualCriteriaScored = 0;
    let actualCriteriaApproved = 0;

    for (const categoryName in responseData[companies[0]]) {
      if (Object.hasOwn(responseData[companies[0]], categoryName)) {
        const categoryData: Category = {
          category: categoryName,
          criteria: [],
          totalAnswers: 0,
          actualAnswers: 0,
        };

        for (const criterionName in responseData[companies[0]][categoryName]) {
          if (
            Object.hasOwn(
              responseData[companies[0]][categoryName],
              criterionName
            )
          ) {
            totalCriteriaScored += 1;

            const criterion: Criterion = {
              name: criterionName,
              requirements:
                criteria[categoryName]?.[criterionName]?.requirements || [],
              status: "Responses needed",
              products: [],
            };

            let allCompaniesAnswered = true;
            let hasNonZeroScore = true;

            companies.forEach((companyName) => {
              const companyData =
                responseData[companyName][categoryName][criterionName];
              const hasDescription = companyData?.response_summary;

              const categoryScores = scores?.[categoryName]?.[criterionName];
              const vendorScore = Array.isArray(categoryScores)
                ? categoryScores.find(
                    (item) => item.vendor_name === companyName
                  )?.score
                : null;

              const score =
                typeof vendorScore === "object"
                  ? vendorScore?.score || null
                  : vendorScore || null;

              if (!hasDescription) {
                allCompaniesAnswered = false;
              } else {
                categoryData.actualAnswers += 1;
              }
              categoryData.totalAnswers += 1;

              // Check if the score is non-zero
              if (score === null || score === 0) {
                hasNonZeroScore = false;
              }

              criterion.products.push({
                name: companyName,
                answers: hasDescription ? 1 : 0,
                description: hasDescription || "",
                is_requirements:
                  companyData?.is_requirements_satisfied || false,
                pages: companyData?.pages || "",
                score,
              });
            });

            if (hasNonZeroScore && scoreCheck) {
              actualCriteriaScored += 1;
            }

            if (scoreCheck) {
              criterion.status = "Score Approved";
              actualCriteriaApproved += 1;
            } else if (allCompaniesAnswered) {
              criterion.status = "Responses completed";
            } else {
              criterion.status = "Responses needed";
            }

            categoryData.criteria.push(criterion);
          }
        }

        totalCriteriaAnswered += categoryData.totalAnswers;
        actualCriteriaAnswered += categoryData.actualAnswers;
        evaluationData.push(categoryData);
      }
    }

    setEvaluationStats({
      totalAnswers: totalCriteriaAnswered,
      actualAnswers: actualCriteriaAnswered,
      totalScored: totalCriteriaScored,
      actualScored: actualCriteriaScored,
      totalApproved: actualCriteriaApproved,
    });
    setEvaluationData(evaluationData);
  };

  useEffect(() => {
    formatEvaluationData(evalData, evalScores);
  }, [evalData, evalScores]);

  const toggleDropdown = (criteriaName: string) => {
    setExpandedCriteria((prevExpanded) =>
      prevExpanded.includes(criteriaName)
        ? prevExpanded.filter((name) => name !== criteriaName)
        : [...prevExpanded, criteriaName]
    );
  };

  const expandAll = () => {
    const allCriteriaNames = evaluationData.flatMap((category) =>
      category.criteria.map((criterion) => criterion.name)
    );
    setExpandedCriteria(allCriteriaNames);
  };

  const collapseAll = () => {
    setExpandedCriteria([]);
  };

  useEffect(() => {
    if (scoreCheck) {
      handleScore();
    }
  }, [scoreCheck]);

  const handleScore = async () => {
    const maxAttempts = 5;
    let attempts = 0;

    const tryApiCall = async () => {
      attempts++;
      const response = await dispatch(rfpResponseScore(Object.keys(evalData)));
      navigate(`${location.pathname}?generateScore`);

      if (response !== 500 && response !== 502 && response !== 400) {
        return;
      }

      if (attempts < maxAttempts) {
        await tryApiCall();
      } else {
        dispatch(
          setShowToast({
            show: true,
            type: "error",
            toastMessage:
              "Maximum retry attempts reached. Please try again later!",
          })
        );
      }
    };

    await tryApiCall();
  };

  const handleStatusChange = (
    categoryIndex: number,
    criterionIndex: number,
    updatedStatus: string
  ) => {
    setEvaluationData((prevData) => {
      const updatedData = prevData?.map((category, idx) =>
        idx === categoryIndex
          ? {
              ...category,
              criteria: category.criteria.map((criterion, cIdx) =>
                cIdx === criterionIndex
                  ? {
                      ...criterion,
                      status: updatedStatus,
                    }
                  : criterion
              ),
            }
          : category
      );

      const approvedCriteriaCount = updatedData?.reduce((total, category) => {
        return (
          total +
          category.criteria.filter(
            (criterion) => criterion.status === "Score Approved"
          ).length
        );
      }, 0);

      setEvaluationStats((prevStats) => ({
        ...prevStats,
        totalApproved: approvedCriteriaCount,
      }));
      return updatedData;
    });
  };

  const handleBack = () => {
    navigate("/invite");
  };

  const cardData = [
    {
      title: "Criteria Answered",
      actual: "actualAnswers" as keyof EvaluationStats,
      total: "totalAnswers" as keyof EvaluationStats,
    },
    {
      title: "Criteria Scored",
      actual: "actualScored" as keyof EvaluationStats,
      total: "totalScored" as keyof EvaluationStats,
    },
    {
      title: "Criteria Approved",
      actual: "totalApproved" as keyof EvaluationStats,
      total: "totalScored" as keyof EvaluationStats,
    },
  ];

  return (
    <>
      <div className="bg-white p-8 flex flex-col gap-6">
        {/* Header Section */}
        <div className="flex flex-col md:flex-row justify-between items-center gap-4">
          <div className="flex flex-col md:flex-row gap-2 md:items-center md:gap-4 items-start">
            <ArrowLeftOutlined onClick={handleBack} />
            <Typography variant="Heading 2" text="Evaluation Scorecard" />
            {!scoreCheck && (
              <div className="flex flex-row text-[#135200] items-center gap-1 bg-[#F6FFED]">
                <FileTextOutlined className="h-3 w-3" />
                <Typography variant="SM" text="Evaluating" />
              </div>
            )}
          </div>
          <div className="w-full md:w-auto flex flex-row md:flex-row gap-2 items-center justify-center">
            <Button
              type="Default"
              buttonText="Share"
              icon
              iconComponent={<UsergroupAddOutlined />}
            />
            <Button
              type={
                evaluationStats?.totalApproved === evaluationStats?.totalScored
                  ? "Primary"
                  : "Disabled"
              }
              buttonText="Create Report"
              icon
              iconComponent={<BarChartOutlined />}
              onClick={() => navigate("/create_report")}
            />
          </div>
        </div>
        {/* Summary Section */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {cardData.map(({ title, actual, total }, index) => (
            <div key={index} className="p-4 bg-white shadow-md rounded-lg">
              <Typography
                text={title}
                variant="Base"
                className="text-[#00000099]"
              />
              <div className="flex flex-row gap-2">
                {evaluationStats?.[actual] === evaluationStats?.[total] && (
                  <CheckCircleFilled className="text-[#52C41A]" />
                )}
                <Typography
                  text={`${evaluationStats?.[actual]}/${evaluationStats?.[total]}`}
                  variant="Heading 3"
                />
              </div>
            </div>
          ))}
        </div>
        {isLoading ? (
          <>
            {message?.info?.type === 1 ? (
              <div className="flex justify-center items-center h-full mt-10">
                <ProgressBarComponent
                  steps={message?.task_list}
                  currentStatus={message?.status_id}
                  progress={message?.info?.percentage || 0}
                  progressHeading={message?.info?.progressbar}
                  className=""
                />
              </div>
            ) : (
              <div className="fixed inset-0 flex items-center justify-center z-[9999] text-center">
                <Loading mainHeading="Importing Data for Evaluation." />
              </div>
            )}
          </>
        ) : (
          <>
            {/* Search and Action Buttons */}
            <div className="flex flex-col md:flex-row justify-between items-center gap-4">
              <Input
                placeholder="Search"
                state="Default"
                size="Small"
                iconPosition="right"
                icon={<SearchOutlined />}
                className="w-full md:w-1/3"
              />
              <div className="flex flex-row gap-2">
                <Button
                  icon
                  iconComponent={<BsArrowsCollapse />}
                  onClick={collapseAll}
                />
                <Button icon iconComponent={<MdExpand />} onClick={expandAll} />
                <Button type="Default" buttonText="Import Notes" />
                {!scoreCheck && (
                  <Button
                    type="Primary"
                    buttonText="Generate Scores"
                    onClick={handleScore}
                  />
                )}
              </div>
            </div>
            {/* Table Section */}
            {evaluationData?.map((category, categoryIndex) => (
              <div
                key={category.category}
                className="bg-white shadow rounded-lg overflow-hidden mt-6"
              >
                <Typography
                  variant="Heading 4"
                  text={category.category}
                  className="p-4"
                />
                <div className="overflow-x-auto">
                  <table className="w-full text-left">
                    <thead>
                      <tr className="h-[54px]">
                        <th className="w-[40px] p-4" />
                        <th className="w-[250px] p-4">
                          <Typography variant="Base" text="Criteria" strong />
                        </th>
                        <th className="w-[350px] p-4 hidden md:table-cell">
                          <Typography
                            variant="Base"
                            text="Requirements"
                            strong
                          />
                        </th>
                        <th className="w-[190px] p-4">
                          <Typography variant="Base" text="Status" strong />
                        </th>
                        {Object.keys(evalData)?.map((companyName) => (
                          <th
                            className="w-[170px] p-4 hidden md:table-cell"
                            key={companyName}
                          >
                            <Typography
                              variant="Base"
                              text={companyName}
                              strong
                            />
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {category.criteria.map((criterion, index) => (
                        <>
                          <tr key={index} className="border-t border-b">
                            <td className="p-4 text-[#00000099]">
                              {expandedCriteria.includes(criterion.name) ? (
                                <CaretDownOutlined
                                  onClick={() => toggleDropdown(criterion.name)}
                                />
                              ) : (
                                <CaretRightOutlined
                                  onClick={() => toggleDropdown(criterion.name)}
                                />
                              )}
                            </td>
                            <td className="p-4 border-r border-[#0000000F]">
                              <Typography
                                variant="Base"
                                text={criterion.name}
                                strong
                              />
                            </td>

                            <td className="p-4 border-r border-[#0000000F] hidden md:table-cell">
                              <div className="flex flex-wrap gap-2">
                                {criterion.requirements.map((req, idx) => (
                                  <div
                                    key={idx}
                                    className="bg-[#0000000F] rounded-md px-2 py-1 cursor-pointer"
                                  >
                                    <Typography variant="SM" text={req} />
                                  </div>
                                ))}
                              </div>
                            </td>
                            <td className="p-4 border-r border-[#0000000F]">
                              <select
                                className="px-2 w-fit py-1 rounded-lg outline-none focus:outline-none"
                                value={criterion.status}
                                style={{
                                  backgroundColor:
                                    criterion.status === "Responses needed"
                                      ? "#FFFBE6"
                                      : criterion.status ===
                                          "Responses completed"
                                        ? "#F9F0FF"
                                        : criterion.status === "Scoring"
                                          ? "#F0F5FF"
                                          : "#F6FFED",
                                  color:
                                    criterion.status === "Responses needed"
                                      ? "#874D00"
                                      : criterion.status ===
                                          "Responses completed"
                                        ? "#22075E"
                                        : criterion.status === "Scoring"
                                          ? "#061178"
                                          : "#135200",
                                }}
                                onChange={(e) =>
                                  handleStatusChange(
                                    categoryIndex,
                                    index,
                                    e.target.value
                                  )
                                }
                              >
                                <option value="Responses needed">
                                  <Typography
                                    variant="SM"
                                    text="Responses needed"
                                  />
                                </option>
                                <option value="Responses completed">
                                  <Typography
                                    variant="SM"
                                    text="Responses completed"
                                  />
                                </option>
                                {scoreCheck && (
                                  <>
                                    <option value="Scoring">
                                      <Typography variant="SM" text="Scoring" />
                                    </option>
                                    <option value="Score Approved">
                                      <Typography
                                        variant="SM"
                                        text="Score Approved"
                                      />
                                    </option>
                                  </>
                                )}
                              </select>
                            </td>

                            {criterion.products.map((product, index) => (
                              <td
                                key={index}
                                className="p-4 border-r border-[#0000000F] hidden md:table-cell relative"
                              >
                                {/* Centered score */}
                                <div className="flex justify-center items-center h-full">
                                  {scoreCheck && (
                                    <Typography
                                      text={product.score}
                                      variant="Base"
                                      className="w-fit"
                                    />
                                  )}
                                </div>

                                {/* Bottom-right icon */}
                                {product.is_requirements ? (
                                  <CheckCircleFilled className="text-[#52C41A] absolute bottom-2 right-2" />
                                ) : (
                                  <CloseCircleFilled className="text-[red] absolute bottom-2 right-2" />
                                )}
                              </td>
                            ))}
                          </tr>
                          {expandedCriteria.includes(criterion.name) && (
                            <tr>
                              <td colSpan={7} className="px-4 pt-4">
                                <div className="flex flex-col gap-4">
                                  {criterion.products.map((product, index) => (
                                    <div key={index} className="border-b p-2">
                                      <div className="flex flex-row items-center">
                                        <Typography
                                          variant="Base"
                                          text={product.name}
                                          strong
                                          className="pr-2"
                                        />
                                        <b>.</b>
                                        <Typography
                                          variant="Base"
                                          text={`${product.answers} answer`}
                                          className="text-[#00000099] px-2"
                                        />
                                        <b>.</b>
                                        <Button
                                          type="Text"
                                          buttonText="Add an answer"
                                          size="Small"
                                          icon
                                          iconComponent={<PlusOutlined />}
                                          className="p-0"
                                        />
                                      </div>
                                      <Typography
                                        variant="Base"
                                        text={product.description}
                                      />
                                      <Typography
                                        variant="SM"
                                        text={`[Pages: ${product.pages}]`}
                                      />
                                    </div>
                                  ))}
                                </div>
                              </td>
                            </tr>
                          )}
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default EvaluationScorecard;
