import Menu from "style-guide/component/Menu";
import Typography from "style-guide/component/Typography";
import ProjectOverview from "./overview";
import { useEffect, useState } from "react";
import BuildTemplateFlow from "./template";
import UploadResponse from "./uploadResponse";
import Invite from "../invite";
import ProgressBar from "style-guide/component/Progressbar";

const RfpFree = () => {
  const rfpFreeKeyValue = {
    overview: false,
    review: false,
    requirement: false,
    weight: false,
    weightDone: false,
    upload: false,
    invite: false,
  };
  const [rfpFreeStep, setRfpFreeStep] = useState(rfpFreeKeyValue);
  const intakeMark = localStorage.getItem("intake");

  const updateSliderSteps = (name: string, keyValue: boolean) => {
    setRfpFreeStep(rfpFreeKeyValue);
    setRfpFreeStep((prevState) => ({ ...prevState, [name]: keyValue }));
  };

  useEffect(() => {
    updateSliderSteps("overview", true);
    if (!intakeMark) {
      localStorage.setItem("intake", "overview");
    }
  }, []);

  useEffect(() => {
    if (intakeMark === null) {
      updateSliderSteps("overview", true);
    } else if (intakeMark !== null) {
      updateSliderSteps(intakeMark, true);
    }
  }, []);

  const openSelectedStep = (step: string) => {
    localStorage.setItem("intake", step);
    updateSliderSteps(step, true);
  };

  const progressStepMapping: string[][] = [
    ["overview", "review"], // Step 1
    ["requirement", "weight", "weightDone"], // Step 2
    ["upload"], // Step 3
    ["invite"], // Step 4
  ];
  
  const getCurrentStepIndex = () => {
    for (let i = 0; i < progressStepMapping.length; i++) {
      if (
        progressStepMapping[i].some(
          (step) => rfpFreeStep[step as keyof typeof rfpFreeStep]
        )
      ) {
        return i + 1; // 1-based index
      }
    }
    return 0;
  };

  const currentStep = getCurrentStepIndex();

  return (
    <div className="overflow-x-auto w-full min-h-screen pb-[100px] pt-12 px-6 md:px-12 font-helvetica-neue flex flex-col gap-4">
      {/* Header Section */}
      <div className="flex flex-row justify-between">
        <Typography
          text="Nozma"
          variant="LG"
          className="text-blue-primary"
          strong
        />
        <Menu />
      </div>

      {/* Progress Bar */}
      <ProgressBar currentStep={currentStep} />

      {(rfpFreeStep?.overview || rfpFreeStep?.review) && (
        <ProjectOverview openSelectedStep={openSelectedStep} />
      )}
      {(rfpFreeStep?.requirement ||
        rfpFreeStep?.weight ||
        rfpFreeStep?.weightDone) && (
        <BuildTemplateFlow openSelectedStep={openSelectedStep} />
      )}
      {rfpFreeStep?.upload && (
        <UploadResponse openSelectedStep={openSelectedStep} />
      )}
      {rfpFreeStep?.invite && <Invite openSelectedStep={openSelectedStep} />}
    </div>
  );
};

export default RfpFree;
