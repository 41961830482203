import React, { useEffect, useState } from "react";
import Typography from "style-guide/component/Typography";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  CloseOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {  RootState } from "redux/store";
import {  useSelector } from "react-redux";
import { ChipsData, WeightsData } from "../../../../types/templates";

// type CurrentComponentType = "requirement" | "weight" | "weightDone" | "upload";

interface BuildTemplateProps {
  currentComponent: string | null | undefined;
  chipsData: ChipsData;
  setChipsData: React.Dispatch<React.SetStateAction<ChipsData>>;
  setCriteriaWeights: React.Dispatch<React.SetStateAction<WeightsData>>;
  criteriaWeight: WeightsData;
  setTotalWeight: React.Dispatch<React.SetStateAction<number>>;
  totalWeight: number;
}

const BuildTemplate: React.FC<BuildTemplateProps> = ({
  currentComponent,
  chipsData,
  setChipsData,
  setCriteriaWeights,
  criteriaWeight,
  totalWeight,
  setTotalWeight,
}) => {
  const [showInput, setShowInput] = useState<Record<string, boolean>>({});
  const [error, setError] = useState<string>("");
  const [inputValue, setInputValue] = useState<string>("");
  const [selectedCell, setSelectedCell] = useState<string | null>(null);

  const handleCellClick = (reqKey: string, criteriaKey: string) => {
    setSelectedCell(`${reqKey}-${criteriaKey}`);
  };
  const { criteria, weights } = useSelector(
    (state: RootState) => state.templatesData
  );

  useEffect(() => {
    if (criteria) {
      const initialChipsData: ChipsData = {};

      Object.keys(criteria).forEach((reqKey) => {
        initialChipsData[reqKey] = {};
        Object.keys(criteria[reqKey]).forEach((criteriaKey) => {
          initialChipsData[reqKey][criteriaKey] = {
            requirements: criteria[reqKey][criteriaKey].requirements || [],
          };
        });
      });

      setChipsData(initialChipsData);
    }
  }, [criteria, setChipsData]);

  useEffect(() => {
    if (weights) {
      const initialWeightsData: WeightsData = {};

      Object.keys(weights).forEach((reqKey) => {
        initialWeightsData[reqKey] = {};
        Object.keys(weights[reqKey]).forEach((weightKey) => {
          initialWeightsData[reqKey][weightKey] = {
            weight: weights[reqKey][weightKey].weight || 0,
          };
        });
      });

      setCriteriaWeights(initialWeightsData);
    }
  }, [weights]);

  useEffect(() => {
    const total = Object.values(criteriaWeight).reduce((acc, reqWeights) => {
      return (
        acc +
        Object.values(reqWeights).reduce((sum, { weight }) => {
          return sum + (weight || 0);
        }, 0)
      );
    }, 0);
    setTotalWeight(total);
    if (total !== 100) {
      setError("Total weight must be 100%");
    }
  }, [criteriaWeight]);

  const RequirementKeys = Object.keys(criteria || {});

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    reqKey: string,
    criteriaKey: string
  ) => {
    if (e.key === "Enter" && e.currentTarget.value.trim() !== "") {
      const newChip = e.currentTarget.value.trim();
      setChipsData((prev) => {
        const updatedRequirements = [
          ...prev[reqKey][criteriaKey].requirements,
          newChip,
        ];

        const updatedChipsData = {
          ...prev,
          [reqKey]: {
            ...prev[reqKey],
            [criteriaKey]: { requirements: updatedRequirements },
          },
        };
        return updatedChipsData;
      });

      setShowInput((prev) => ({
        ...prev,
        [`${reqKey}-${criteriaKey}`]: false,
      }));
      e.currentTarget.value = "";
    }
  };

  const handleDeleteChip = (
    reqKey: string,
    criteriaKey: string,
    chipIndex: number
  ) => {
    setChipsData((prev) => {
      const updatedRequirements = prev[reqKey][criteriaKey].requirements.filter(
        (_, i) => i !== chipIndex
      );

      return {
        ...prev,
        [reqKey]: {
          ...prev[reqKey],
          [criteriaKey]: { requirements: updatedRequirements },
        },
      };
    });
  };

  const handleAddClick = (reqKey: string, criteriaKey: string) => {
    setInputValue("");
    setShowInput((prev) => ({
      ...prev,
      [`${reqKey}-${criteriaKey}`]: true,
    }));
  };

  const handleWeightChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    reqKey: string,
    criteriaKey: string
  ) => {
    const newWeight = parseFloat(e.target.value) || 0;
    setCriteriaWeights((prev) => ({
      ...prev,
      [reqKey]: {
        ...prev[reqKey],
        [criteriaKey]: { weight: newWeight },
      },
    }));
  };

  return (
    <>
      <div className="flex flex-col gap-8">
        {/* Instruction Section */}
        <div className="gap-2 flex flex-col justify-between">
          <Typography
            text={
              currentComponent === "requirement"
                ? "Here are your suggested requirements."
                : currentComponent === "weight"
                  ? "Finally, let’s generate weights for each criteria."
                  : "Here are your suggested weights."
            }
            variant="Heading 3"
            className="text-black"
          />
          <Typography
            text={
              currentComponent === "requirement"
                ? "We've generated these requirements based on your inputs and what similar firms have used. You can edit them or add your own to make sure they fit your specific needs."
                : currentComponent === "weight"
                  ? "Weights allow you to assign a relative level of importance to each criteria. We can set a baseline for you, based on evaluations performed by comparable firms addressing similar business problems. This step is optional, and you can always edit this later."
                  : "We've generated these weights based on your inputs and what similar firms have used. You can edit them to make sure they fit your specific needs."
            }
            variant="LG"
            className="text-black"
          />
        </div>

        {currentComponent === "weightDone" && (
          <div className="w-[20%] flex flex-col gap-2">
            <Typography variant="Base" text="Weights Assigned" />
            <div className="flex flex-row gap-2">
              {totalWeight === 100 ? (
                <CheckCircleFilled className="text-[#52C41A]" />
              ) : (
                <CloseCircleFilled className="text-[red]" />
              )}
              <Typography variant="Heading 3" text={`${totalWeight}%`} />
            </div>
          </div>
        )}

        {/* Mapping requirements and criteria */}
        <div className="w-full flex pt-4 flex-col gap-4 border-[#F0F0F0] border-[1px] rounded-md">
          {RequirementKeys.map((reqKey) => (
            <div className="w-full" key={reqKey}>
              <Typography
                text={reqKey}
                variant="Heading 4"
                className="mb-2 pl-4"
              />
              <table className="w-full border border-[#E0E0E0] rounded-md">
                <thead>
                  <tr>
                    <th className="p-4 text-left">
                      <Typography variant="Base" text="Criteria" strong />
                    </th>
                    <th className="p-4 text-left">
                      <Typography variant="Base" text="Requirements" strong />
                    </th>
                    <th className="p-4 text-left">
                      <div>
                        <Typography variant="Base" text="Weight" strong />
                        {totalWeight !== 100 &&
                          currentComponent === "weightDone" && (
                            <Typography
                              variant="Base"
                              text={error}
                              className="text-[red]"
                            />
                          )}
                      </div>
                    </th>
                    <th className="p-4 text-left">
                      <Typography variant="Base" text="Scorers" strong />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(criteria[reqKey] || {}).map((criteriaKey) => {
                    return (
                      <tr key={criteriaKey} className="h-[50px]">
                        <td className="border border-[#0000000F] px-4 w-[340px] h-full">
                          <Typography
                            variant="Base"
                            text={criteriaKey}
                            strong
                          />
                        </td>
                        <td className="border border-[#0000000F] w-[640px] h-full">
                          <div className="w-full p-2 rounded box-sizing">
                            <div className="flex flex-wrap gap-2 mb-2">
                              {currentComponent === "requirement" && (
                                <>
                                  {showInput[`${reqKey}-${criteriaKey}`] ? (
                                    <input
                                      className="w-full border-none outline-none p-1 rounded text-[12px]"
                                      placeholder="Add requirement"
                                      value={inputValue}
                                      onChange={(e) =>
                                        setInputValue(e.target.value)
                                      }
                                      onKeyDown={(e) =>
                                        handleKeyDown(e, reqKey, criteriaKey)
                                      }
                                      onBlur={() =>
                                        setShowInput((prev) => ({
                                          ...prev,
                                          [`${reqKey}-${criteriaKey}`]: false,
                                        }))
                                      }
                                      autoFocus
                                    />
                                  ) : (
                                    <div
                                      className="flex flex-row gap-2 items-center bg-[#0000000F] rounded-md px-2 py-1 cursor-pointer"
                                      onClick={() =>
                                        handleAddClick(reqKey, criteriaKey)
                                      }
                                    >
                                      <Typography
                                        variant="SM"
                                        text="Add requirement"
                                        strong
                                      />
                                      <PlusOutlined className="h-3 w-3" />
                                    </div>
                                  )}
                                </>
                              )}
                              {/* Existing chips */}
                              {(
                                chipsData[reqKey]?.[criteriaKey]
                                  ?.requirements || []
                              ).map((chip: string, chipIndex: number) => (
                                <div
                                  key={chipIndex}
                                  className="flex items-center bg-[#0000000F] rounded-md px-2 py-1"
                                >
                                  <Typography variant="SM" text={chip} />
                                  {currentComponent === "requirement" && (
                                    <CloseOutlined
                                      className="ml-1 cursor-pointer h-3 w-3"
                                      onClick={() =>
                                        handleDeleteChip(
                                          reqKey,
                                          criteriaKey,
                                          chipIndex
                                        )
                                      }
                                    />
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        </td>
                        <td
                          className="px-4 text-center border border-[#E0E0E0 w-[150px]]"
                          style={
                            selectedCell === `${reqKey}-${criteriaKey}` &&
                            currentComponent === "weightDone"
                              ? { border: "2px solid #0F428D" }
                              : undefined
                          }
                          onClick={() => handleCellClick(reqKey, criteriaKey)}
                        >
                          {currentComponent === "weightDone" ? (
                            <input
                              className="w-full h-full border-none outline-none p-0 text-center"
                              value={
                                criteriaWeight[reqKey]?.[criteriaKey]?.weight ||
                                ""
                              }
                              onChange={(e) =>
                                handleWeightChange(e, reqKey, criteriaKey)
                              }
                            />
                          ) : null}
                        </td>

                        <td className="w-[150px] border p-2 text-center h-full">
                          <input className="w-full h-full border-none outline-none p-1 rounded" />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default BuildTemplate;
