import React, { useState } from "react";

const CustomToggle: React.FC<{
  defaultChecked?: boolean;
  onChange?: (checked: boolean) => void;
}> = ({ defaultChecked = false, onChange }) => {
  const [isChecked, setIsChecked] = useState(defaultChecked);

  const handleToggle = () => {
    const newChecked = !isChecked;
    setIsChecked(newChecked);
    if (onChange) onChange(newChecked);
  };

  return (
    <div
      className={`relative w-12 h-6 flex items-center rounded-full cursor-pointer transition-all ${
        isChecked ? "bg-[#0F428D]" : "bg-gray-300"
      }`}
      onClick={handleToggle}
    >
      <div
        className={`absolute w-5 h-5 bg-white rounded-full shadow-md transform transition-transform ${
          isChecked ? "translate-x-6" : "translate-x-1"
        }`}
      />
    </div>
  );
};

export default CustomToggle;
